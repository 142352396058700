import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-loaderror-survey',
    templateUrl: './popup-loaderror-survey.component.html',
    styleUrls: ['./popup-loaderror-survey.component.scss'],
})
export class PopupLoadErrorSurvey implements OnInit {
    private appService: AppService;
    @Input() text = '';
    @Input() button = 'Понятно';

    constructor(
        appService: AppService
    ) {
        this.appService = appService;
    }

    ngOnInit() {
    }

    close() {
        this.appService.closeModal();
    }
}
