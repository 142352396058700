import {Component, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../services/app.service';
import {UserService} from '../../models/user.service';
import {NavigationService} from '../../services/navigation.service';
import {CartService} from 'src/app/models/cart.service';
import {AnalyticsService} from '../../services/analytics.service';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {HelperService} from '../../services/helper.service';
import {PizzaService} from '../../models/pizza.service';
import {PizzaItem, PizzaSizeType} from '../../entity/pizzaItem.entity';
import {ModalService} from "../../services/modal.service";
import {GestureController} from "@ionic/angular";
import {MainModal} from "../../core/main.Modal";
import Scrollbar from "smooth-scrollbar";
import CustomScrollBarPlugin from "../../core/CustomScrollBarPlugin";


@Component({
    selector: 'pizza-modal',
    templateUrl: 'pizza-modal.html',
    styleUrls: ['pizza-modal.scss']
})
export class PizzaModalComponent extends MainModal implements OnInit, OnDestroy {

    @ViewChild('scrollContainer', {static: false})
    scrollContainer;

    @Input() public pizzaId: string;
    @Input() public quantity = 1;

    public pageReady = false;
    public pageShow = false;
    public pageShowCalled = false;

    private ngZone: NgZone;
    public pizzaService: PizzaService;
    private cartService: CartService;
    scrollY = 50;
    opacity = 0;
    infoOpacity = 0;
    modalContentTop = 10;

    leftSwiperIndex = 1;
    leftSwiperActiveIndex = 0;
    leftSwiperProgress = 0;
    leftSwiperRealProgress = 0;
    leftSliderOptions: SwiperConfigInterface = {
        // pagination: {
        //     el: '.swiper-pagination',
        //     type: 'bullets',
        //     clickable: true
        // },
        slidesPerView: 3,
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        observer: true,
        freeMode: true,
        freeModeSticky: true,
        // loop: true,
        // loopAdditionalSlides: 1,
        // loopedSlides: 1,
        direction: 'vertical',
        freeModeMomentum: true,
        watchSlidesProgress: true,
        // speed: 1,
        // freeModeMomentumVelocityRatio: 5,
        // freeModeMomentumRatio: 5,
        // speed: 1000,
        cssMode: true
    };

    rightSwiperIndex = 1;
    rightSwiperProgress = 0;
    rightSliderOptions: SwiperConfigInterface = {
        // pagination: {
        //     el: '.swiper-pagination',
        //     type: 'bullets',
        //     clickable: true
        // },
        slidesPerView: 3,
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        observer: true,
        freeMode: true,
        freeModeSticky: true,
        // loop: true,
        // loopAdditionalSlides: 1,
        // loopedSlides: 1,
        direction: 'vertical',
        freeModeMomentum: true,
        watchSlidesProgress: true,
        // speed: 1,
        // freeModeMomentumVelocityRatio: 5,
        // freeModeMomentumRatio: 5,
        // speed: 1000,
        cssMode: true
    };

    public loadingCounter = 3;
    public selectedPizzaParts: PizzaItem[];
    public totalPrice = 0;
    private leftSlider: SwiperComponent;
    private leftSliderSwiper: any;
    private rightSlider: SwiperComponent;
    private rightSliderSwiper: any;

    public mode: 'slider' | 'selected' = 'selected';
    public PizzaSizeType = PizzaSizeType;
    public pizzaSize: PizzaSizeType = PizzaSizeType.Medium;
    public helpTextOpen = false;

    public sliderLeftTouching = false;
    public sliderRightTouching = false;

    public sliderLeftMoving = false;
    public sliderLeftMovingTimer: any;
    public sliderRightMoving = false;
    public sliderRightMovingTimer: any;

    private wrongSliderLeftPosition = false;
    private wrongSliderRightPosition = false;

    public sliderLastChanged: 'left' | 'right';
    public sliderLastDirection: 'up' | 'down';
    public sliderLastProgress = 0;

    public nextClickLeft = false;
    public nextClickRight = false;

    public animationLeftLocked = false;
    public animationRightLocked = false;

    public subs: any = [];
    public sizeTime = null;
    navigationService: NavigationService;
    userService: UserService;
    swiperLock: boolean = false;
    private scrollBar: any;
    swiperScrollLock: boolean = false;

    constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController,
        navigationService: NavigationService,
        userService: UserService,
        pizzaService: PizzaService,
        cartService: CartService,
        ngZone: NgZone
    ) {
        super(appService, modalService, gestureCtrl);

        this.navigationService = navigationService;
        this.userService = userService;
        this.pizzaService = pizzaService;
        this.cartService = cartService;
        this.ngZone = ngZone;
        this.onScroll = this.onScroll.bind(this);
    }

    @HostListener('document:touchmove', ['$event'])
    onTouchMove(event: Event) {
        event.stopPropagation();
    }

    ngOnInit() {
        AnalyticsService.pageVisit('Pizza Конструктор');

        if (this.pizzaId !== undefined) {
            this.mode = 'slider';
        }

        this.pizzasReady();
        setTimeout(() => {
            this.createVirtualScroll(this.scrollContainer.nativeElement);
        });

        // this.pizzaService.getPizza(status => {
        //     if (status) {
        //
        //         this.pageReady = true;
        //         this.pizza = this.pizzaService.pizzaConstructor;
        //
        //         if (this.pizzaId !== undefined) {
        //             this.changePizzaConstructor();
        //         }
        //
        //     }
        // });
    }

    createVirtualScroll(targetElement) {
        Scrollbar.use(CustomScrollBarPlugin);
        this.scrollBar = Scrollbar.init(
            targetElement,
            {
                damping: 0.1,
                plugins: {
                    myPlugin: {
                        isModalGesture: () => this.modalService.isGesture
                    }
                }
            });
        this.scrollBar.addListener(this.onScroll)
        this.onScroll({offset: {x: 0, y: 0}})
    }

    ngOnDestroy(): void {
        if (this.subs.length) {
            this.subs.forEach((item) => {
                item.unsubscribe();
            });
        }

        this.swiperLock = false;
        this.swiperScrollLock = false;
        if(this.scrollBar){
            this.scrollBar.removeListener(this.onScroll);
            this.scrollBar.destroy();
            this.scrollBar = null;
        }
    }

    loadPizzaFromCart() {
        const result = this.pizzaService.getSelectedPizzasByCartId(this.pizzaId);

        this.selectedPizzaParts = result.selectedPizzas;
        this.pizzaSize = result.size;

        this.leftSwiperIndex = result.index[0];
        this.rightSwiperIndex = result.index[1];

        // this.leftSwipe(this.leftSwiperIndex, 0);
        // this.rightSwipe(this.rightSwiperIndex, 0);
    }

    onScroll(e: any) {
        if(this.modalService && e.detail && !(this.sliderLeftTouching || this.sliderRightTouching)){
            this.modalService.scrollModal(e.detail.scrollTop)
        }else if(e.offset && !(this.sliderLeftTouching || this.sliderRightTouching)){
            this.modalService.scrollModal(e.offset.y)
        }
    }

    ionViewWillEnter() {
        this.appService.hideFooterMenu = true;
    }

    addToCart() {

        if (this.pizzaId) {
            this.cartService.addToCart({
                id: this.pizzaId
            }, -10000, null, null, 0);
        }

        const resultPizza = this.pizzaService.getCartObject(this.selectedPizzaParts, this.pizzaSize);

        if (this.pizzaId) {
            this.cartService.addToCart(resultPizza, this.quantity, null, null, 0, false, (status) => {
                if (status) {
                    this.close();
                }
            });
        } else {
            this.cartService.addToCart(resultPizza, this.quantity);

            setTimeout(() => {
                this.close();
            }, 500);
        }

    }

    leftSliderReady(leftSlider: SwiperComponent) {
        this.leftSlider = leftSlider;
        this.leftSliderSwiper = leftSlider.directiveRef.swiper();

        // setTimeout(() => {
        // console.log(this.leftSwiperIndex);
        // this.leftSlider.directiveRef.setIndex(this.leftSwiperIndex, 0);

        this.pizzasReady();
        // }, 100);
    }

    onIndexChangeLeftSlider(index: number) {

        if (this.pageReady && this.mode === 'slider' && this.pageShow) {

            // console.log('onIndexChangeLeftSlider', index);

            this.leftSwiperIndex = index;

            this.selectPizzaParts();
        }

    }

    rightSliderReady(rightSlider: SwiperComponent) {
        this.rightSlider = rightSlider;
        this.rightSliderSwiper = rightSlider.directiveRef.swiper();

        // console.log();

        // console.log(document
        //     .getElementsByClassName('.pizza-constructor-slider-right-wrapper'));

        // document
        //     .getElementsByClassName('.pizza-constructor-slider-right-wrapper')[0]
        //     .getElementsByClassName('.swiper-wrapper')[0]
        //     .addEventListener('scroll', (e) => {
        //         console.log(e);
        //     });

        // setTimeout(() => {
        // console.log(this.rightSwiperIndex);
        // this.rightSlider.directiveRef.setIndex(this.rightSwiperIndex, 0);

        this.pizzasReady();
        // }, 100);
    }

    pizzasReady() {
        this.loadingCounter--;
        if (this.loadingCounter === 0) {

            setTimeout(() => {

                if (this.appService.isAndroid()) {
                    this.leftSwipe(5, 0);
                    this.rightSwipe(5, 0);
                }

                if (this.pizzaId !== undefined) {

                } else {
                    this.selectPizzas();
                }

                setTimeout(() => {
                    if (this.pizzaId !== undefined) {

                    } else {
                        this.pizzaSize = PizzaSizeType.Small;
                        this.unSelectPizzas();
                    }

                    setTimeout(() => {

                        if (this.pizzaId !== undefined) {

                            // setTimeout(() => {
                            this.loadPizzaFromCart();

                            this.pageReady = true;

                            setTimeout(() => {
                                this.checkForSamePizza('left');
                            }, 200);

                            // }, 500);

                        } else {
                            // setTimeout(() => {
                            this.random(0);

                            setTimeout(() => {
                                this.rightSwipe(this.rightSwiperIndex);
                                this.leftSwipe(this.leftSwiperIndex);

                                this.pageReady = true;

                                setTimeout(() => {
                                    this.checkForSamePizza('right');
                                }, 100);
                            }, 200);

                            // this.selectPizzaParts();


                            // }, 500);

                        }

                    }, 100);

                }, 100);

            }, 300);

        }
    }

    onIndexChangeRightSlider(index: number) {

        if (this.pageReady && this.mode === 'slider' && this.pageShow) {

            // console.log('onIndexChangeRightSlider', index);

            this.rightSwiperIndex = index;

            this.selectPizzaParts();
        }

    }

    // private problematicTouch = null;

    swiperTouchStart($event: any, slider: string) {
        if (slider === 'left') {
            this.sliderLeftTouching = true;
        }

        if (slider === 'right') {
            this.sliderRightTouching = true;
        }

        this.modalService.scrollModal(1);

        // console.log('swiperTouchStart', $event);

        // if (this.sliderMoving) {
        //     // const progress = HelperService.precisionRound($event[0].progress, 3);
        //     // if (progress !== null && progress % 5 !== 0) {
        //
        //     console.log('problematicTouch SET');
        //     this.problematicTouch = slider;
        //     // }
        // }
        //
        if (slider === 'left') {
            this.rightSwipe(this.rightSwiperIndex);
        }

        if (slider === 'right') {
            this.leftSwipe(this.leftSwiperIndex);
        }
    }

    swiperTouchMove($event: any) {
        // console.log('swiperTouchMove', $event);
    }

    swiperTouchEnd($event: any, slider: string) {

        // console.log('swiperTouchEnd', $event);

        if (slider === 'left') {
            this.sliderLeftTouching = false;
        }

        if (slider === 'right') {
            this.sliderRightTouching = false;
        }

        // this.checkForSamePizza(slider);
        this.modalService.scrollModal(0);

        if ($event[1].type === 'touchend') {

            if (this.wrongSliderLeftPosition) {
                this.wrongSliderLeftPosition = false;
                this.leftSwipe(this.leftSwiperIndex);
            }

            if (this.wrongSliderRightPosition) {
                this.wrongSliderRightPosition = false;
                this.rightSwipe(this.rightSwiperIndex);
            }

            // if (this.problematicTouch !== null) {
            //
            //     console.log('problematicTouch GET');
            //
            //     if (this.problematicTouch === 'left') {
            //         this.leftSwipe(this.leftSwiperIndex);
            //     }
            //
            //     if (this.problematicTouch === 'right') {
            //         this.rightSwipe(this.rightSwiperIndex);
            //     }
            //
            //     this.problematicTouch = null;
            // }

            // if (!this.sliderMoving && !this.sliderTouching && this.pageReady) {
            //     const progress = HelperService.precisionRound($event[0].progress, 3);
            //
            //     // alert(progress);
            //
            //     console.log(progress);
            //
            //     if (progress !== null && progress % 5 !== 0) {
            //         if (slider === 'left') {
            //             this.leftSwipe(this.leftSwiperIndex);
            //         }
            //
            //         if (slider === 'right') {
            //             this.rightSwipe(this.rightSwiperIndex);
            //         }
            //     }
            // }

            // if (slider === 'left') {
            //     this.rightSwipe(this.rightSwiperIndex);
            // }
            //
            // if (slider === 'right') {
            //     this.leftSwipe(this.leftSwiperIndex);
            // }
        }

        // if (!this.sliderMoving && !this.sliderTouching && this.pageReady) {
        // const progress = HelperService.precisionRound($event[0].progress, 3);
        //
        // // alert(progress);
        //
        // console.log(progress);
        //
        // if (progress !== null && progress % 5 !== 0) {
        //     if (slider === 'left') {
        //         this.leftSwipe(this.leftSwiperIndex);
        //     }
        //
        //     if (slider === 'right') {
        //         this.rightSwipe(this.rightSwiperIndex);
        //     }
        // }
        // }
    }


    getProgressFor(progress, step, index) {

    }

    checkForSamePizza(lastChangedSlider: string, progress = null) {

        if (!this.sliderLeftMoving && !this.sliderLeftTouching && !this.sliderRightMoving && !this.sliderRightTouching && this.pageReady) {

            if (this.pageShowCalled === false) {
                this.pageShowCalled = true;

                setTimeout(() => {
                    this.appService.hideLoading();
                    this.pageShow = true;
                }, 300);

                this.selectPizzaParts();

                if (this.pizzaId !== undefined) {
                    this.selectPizzas();
                }

            }

            if (this.leftSwiperIndex === this.rightSwiperIndex) {

                let i = this.sliderLastDirection === 'down' ? -1 : 1;

                if (lastChangedSlider === 'right') {

                    if (this.rightSwiperIndex + 1 >= this.pizzaService.data.length) {
                        i = -1;
                    } else if (this.rightSwiperIndex - 1 <= 0) {
                        i = 1;
                    }

                    this.rightSwiperIndex += i;
                    this.rightSwipe(this.rightSwiperIndex);
                }

                if (lastChangedSlider === 'left') {

                    if (this.leftSwiperIndex + 1 >= this.pizzaService.data.length) {
                        i = -1;
                    } else if (this.leftSwiperIndex - 1 <= 0) {
                        i = 1;
                    }

                    this.leftSwiperIndex += i;
                    this.leftSwipe(this.leftSwiperIndex);
                }

            }
        }
    }

    onSwiperProgress($event: any, slider: string) {

        if (slider === 'left' && this.nextClickLeft) {
            this.nextClickLeft = false;
            return;
        }

        if (slider === 'right' && this.nextClickRight) {
            this.nextClickRight = false;
            return;
        }

        const swiper = $event[0];
        const step = 1 / (swiper.slides.length - 1 - 2);

        if (slider === 'left' && this.sliderLeftMovingTimer) {
            clearTimeout(this.sliderLeftMovingTimer);
        }

        if (slider === 'right' && this.sliderRightMovingTimer) {
            clearTimeout(this.sliderRightMovingTimer);
        }

        if (slider === 'left') {
            this.sliderLeftMoving = true;

            this.sliderLeftMovingTimer = setTimeout(() => {
                this.sliderLeftMoving = false;
                this.checkForSamePizza(slider);

                // console.log('move STOP ' + slider, this.sliderLeftTouching);
                const progress = HelperService.precisionRound(swiper.progress, 3);

                if (HelperService.precisionRound(progress * 100, 0) % 5 !== 0) {
                    // if (!this.sliderLeftTouching) {
                    //     // this.leftSwipe(this.leftSwiperIndex);
                    // } else {
                    //     this.wrongSliderLeftPosition = true;
                    // }

                    if (this.sliderRightTouching || this.sliderLeftTouching) {
                        this.wrongSliderLeftPosition = true;
                    }
                }

                // alert();
            }, 200);
        }

        if (slider === 'right') {
            this.sliderRightMoving = true;

            this.sliderRightMovingTimer = setTimeout(() => {
                this.sliderRightMoving = false;
                this.checkForSamePizza(slider);

                // console.log('move STOP ' + slider, this.sliderRightTouching);
                const progress = HelperService.precisionRound(swiper.progress, 3);

                if (HelperService.precisionRound(progress * 100, 0) % 5 !== 0) {

                    if (this.sliderRightTouching || this.sliderLeftTouching) {
                        this.wrongSliderRightPosition = true;
                    }
                    //
                    // if (!this.sliderRightTouching) {
                    //     // alert('yolo ' + progress);
                    //     // this.rightSwipe(this.rightSwiperIndex);
                    // } else {
                    //
                    // }
                }

                // alert();
            }, 200);
        }

        // console.log(swiper.progress);

        if (this.sliderLastProgress >= swiper.progress) {
            this.sliderLastDirection = 'down';
        } else {
            this.sliderLastDirection = 'up';
        }

        this.sliderLastProgress = swiper.progress;

        // console.log(swiper.progress);

        // this.leftSwiperProgress = swiper.progress;
        // this.leftSwiperRealProgress = (swiper.progress - (step * this.leftSwiperActiveIndex)) * 10;


        if (slider === 'left') {
            if (!this.animationLeftLocked) {
                this.ngZone.runOutsideAngular(() => {
                    this.appService.getWindow().requestAnimationFrame(() => {
                        this.scrollAnimation(swiper, step);
                        this.animationLeftLocked = false;
                    });
                });
            }
            this.animationLeftLocked = true;
        }

        if (slider === 'right') {
            if (!this.animationRightLocked) {
                this.ngZone.runOutsideAngular(() => {
                    this.appService.getWindow().requestAnimationFrame(() => {
                        this.scrollAnimation(swiper, step);
                        this.animationRightLocked = false;
                    });
                });
            }
            this.animationRightLocked = true;
        }

    }

    scrollAnimation(swiper, step) {

        swiper.slides.forEach((item, index) => {
            let norm = HelperService.precisionRound(HelperService.norm(swiper.progress, (index + 1) * step, index * step), 2);
            let p = 0;

            // item.setAttribute('attr-norm', norm);
            // item.setAttribute('attr-index', index);

            let scale = 0.5;
            let imgOpacity = 0.5;
            let textOpacity = 0;

            if (norm >= -2 && norm <= 0) {

                norm = Math.abs(norm);

                if (norm >= -1 && norm < 0) {
                    p = norm - 1;
                }

                if (norm >= 0 && norm < 1) {
                    p = norm;
                }

                if (norm >= 1 && norm < 2) {
                    p = Math.abs(norm - 2);
                }

                // item.setAttribute('attr-p', p);

                // item.style.opacity = HelperService.scaleValue(p, [0, 1], [0.25, 1]);

                p = HelperService.changeWithMagnitude(p, 10);

                scale = HelperService.scaleValue(p, [0, 1], [0.5, 1]);
                imgOpacity = HelperService.scaleValue(p, [0, 1], [0.5, 1]);
                textOpacity = p;
            }

            const imgChild = item.getElementsByClassName('pizza-slide-item-img-inner');
            if (imgChild.length) {
                imgChild[0].style.opacity = imgOpacity;
                imgChild[0].style.transform = 'scale(' + scale + ')';
            }

            const textChild = item.getElementsByClassName('pizza-slide-item-text');
            if (imgChild.length) {
                textChild[0].style.opacity = textOpacity;
            }
        });


    }

    getPaginationHeight() {
        return (50 / (this.pizzaService.data.length));
    }

    getPaginationHeightString() {
        return this.getPaginationHeight() + '%';
    }

    getLeftPaginationTop() {
        return (25 + this.getPaginationHeight() * (this.leftSwiperIndex - 1)) + '%';
    }

    getRightPaginationTop() {
        return (25 + this.getPaginationHeight() * (this.rightSwiperIndex - 1)) + '%';
    }

    selectPizzaParts() {
        this.selectedPizzaParts = this.pizzaService.selectPizzaParts(
            this.pizzaService.data[this.leftSwiperIndex - 1].id,
            this.pizzaService.data[this.rightSwiperIndex - 1].id
        );

        this.calculateTotalPrice();
    }

    calculateTotalPrice() {
        this.totalPrice = this.pizzaService.getTotalPrice(this.selectedPizzaParts, this.pizzaSize);
    }

    random(speed = 300) {
        this.leftSwiperIndex = HelperService.getRandomIntExcept(1, this.pizzaService.data.length, this.leftSwiperIndex);
        this.rightSwiperIndex = HelperService.getRandomIntExcept(1, this.pizzaService.data.length, [this.rightSwiperIndex, this.leftSwiperIndex]);

        this.leftSwipe(this.leftSwiperIndex, speed);
        this.rightSwipe(this.rightSwiperIndex, speed);
    }

    unSelectPizzas() {
        this.mode = 'slider';
        this.lockSwiper(false);
    }

    selectPizzas() {

        if (this.rightSwiperIndex === this.leftSwiperIndex) {
            this.checkForSamePizza('left');
        } else {
            if (this.mode === 'slider') {
                // по умолчанию устанавливаем средний размер
                this.pizzaSize = PizzaSizeType.Medium;

                // пересчитываем цены
                this.calculateTotalPrice();

                this.lockSwiper(true);

                setTimeout(() => {
                    this.mode = 'selected';
                }, 100);

            } else {
                this.addToCart();
            }
        }

    }

    openHelpText() {
        this.helpTextOpen = !this.helpTextOpen;
    }

    closeHelpText() {
        this.helpTextOpen = false;
    }

    selectSize(size: PizzaSizeType) {
        this.pizzaSize = size;

        this.calculateTotalPrice();

        // if (this.sizeTime) {
        //     clearTimeout(this.sizeTime);
        // }
        //
        // this.sizeTime = setTimeout(() => {
        //     this.leftSwipe(this.leftSwiperIndex, 0);
        //     this.rightSwipe(this.rightSwiperIndex, 0);
        // }, 210);

    }

    lockSwiper(lock) {

        if (this.appService.isAndroid()) {
            this.leftSwipe(this.leftSwiperIndex);
            this.rightSwipe(this.rightSwiperIndex);

            // if (lock) {
            //     setTimeout(() => {
            //         this.leftSwipe(this.leftSwiperIndex);
            //         this.rightSwipe(this.rightSwiperIndex);
            //     }, 200);
            // }

        } else {
            this.leftSwipe(this.leftSwiperIndex);
            this.rightSwipe(this.rightSwiperIndex);
        }

        this.swiperLock = lock;


        // this.leftSliderSwiper.allowSlideNext = !lock;
        // this.leftSliderSwiper.allowSlidePrev = !lock;
        // this.leftSliderSwiper.allowTouchMove = !lock;
        //
        // this.rightSliderSwiper.allowSlideNext = !lock;
        // this.rightSliderSwiper.allowSlidePrev = !lock;
        // this.rightSliderSwiper.allowTouchMove = !lock;
    }

    leftSwipe(index, speed = 300) {
        if (speed !== 0) {
            this.nextClickLeft = true;
        }

        // console.log('leftSwipe', index);

        this.leftSlider.directiveRef.setIndex(index, speed);
    }

    rightSwipe(index, speed = 300) {
        if (speed !== 0) {
            this.nextClickRight = true;
        }

        // console.log('rightSwipe', index);

        this.rightSlider.directiveRef.setIndex(index, speed);
    }

    onClickButtonClose() {
        this.close();
    }

    onBackBtnClick() {
        this.unSelectPizzas();
    }
}
