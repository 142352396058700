import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
    Output,
    EventEmitter
} from '@angular/core';
import {Product} from 'src/app/entity/product.entity';
import {AppService} from 'src/app/services/app.service';
import {MainItem} from '../../core/main.item';
import {CartService} from '../../models/cart.service';
import {ProductOffer} from '../../entity/productOffer.entity';
import {ProductModalService} from '../../services/product-modal.service';

@Component({
    selector: 'recommend-item',
    styleUrls: ['./recommend-item.scss'],
    templateUrl: 'recommend-item.html'
})

export class RecommendItemComponent extends MainItem implements OnDestroy, OnInit {

    @Input() recommendProduct: Product;
    @Input() offer: ProductOffer = null;
    @Input() syncLoading = false;
    @Input() syncDelay = 0;
    @Output() removeProdFromRecommend = new EventEmitter();
    quantity = 0;

    cartService: CartService;
    appService: AppService;
    cdr: ChangeDetectorRef;
    productModalService: ProductModalService;

    constructor(
        cartService: CartService,
        productModalService: ProductModalService,
        cdr: ChangeDetectorRef,
        appService: AppService
    ) {
        super();

        this.productModalService = productModalService;
        this.cartService = cartService;
        this.appService = appService;
        this.cdr = cdr;
    }

    ngOnInit() {
        this.checkRecommendProductInCart();
    }

    checkRecommendProductInCart() {
        this.cartService.cart.products.forEach((product) => {
            if (product.id === this.recommendProduct.id) {
                this.quantity = product.qty;
            }
        });
    }

    ngOnDestroy() {
    }

    get isNoBuyTime() {
        return !this.recommendProduct.availability && this.recommendProduct.availabilityText;
    }

    addQty(event: Event) {
        if (this.isNoBuyTime){
            return;
        }
        event.stopPropagation();
        if (this.offer) {
            this.quantity = this.cartService.addToCart(
                this.recommendProduct, +1, this.offer.id, null, this.syncDelay, this.syncLoading, null, true
            );
        } else {
            this.quantity = this.cartService.addToCart(
                this.recommendProduct, +1, null, null, this.syncDelay, this.syncLoading, null, true
            );
        }
        this.productModalService.updateItemQuantityInMenu();
    }

    removeQty(event: Event) {
        if (this.isNoBuyTime){
            return;
        }
        event.stopPropagation();
        if (this.offer) {
            this.quantity = this.cartService.addToCart(
                this.recommendProduct, -1, this.offer.id, null, this.syncDelay, this.syncLoading, null, true
            );
        } else {
            this.quantity = this.cartService.addToCart(
                this.recommendProduct, -1, null, null, this.syncDelay, this.syncLoading, null, true
            );
        }
        this.productModalService.updateItemQuantityInMenu();
    }
}
