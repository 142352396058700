import {Entity} from '../core/main.entity';

export class Deposit implements Entity {
    isShowPopup: boolean;
    balance: number;
    text: string;
    link: string;
    infoText: string;
    infoTitle: string;
    infoButton: string;
    balanceBurningDate?: string;
    balanceBurningText?: string;
}
