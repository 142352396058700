import {Entity} from '../core/main.entity';
import {NutritionalValue} from './nutritionalValue.entity';

export class PizzaItem implements Entity {
    id: number;
    title: string;
    desc: string;
    img: string;
    sizes: PizzaSizeItem[];
}


export class PizzaSizeItem implements Entity {
    id: number;
    sizeType: PizzaSizeType;
    price: number;
    fprice: number;
    oldPrice: number;
    weight: string;
    nutritionalValue: NutritionalValue = new NutritionalValue();
}

export enum PizzaSizeType {
    Small = 1,
    Medium = 2
}
