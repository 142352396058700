import {Component, Input, OnInit} from '@angular/core';
import {Deposit} from '../../../entity/deposit.entity';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-deposit',
    templateUrl: './popup-deposit.component.html',
    styleUrls: ['./popup-deposit.component.scss'],
})
export class PopupDepositComponent implements OnInit {

    /*
    * @todo вынести общие для всех модлаьных окон свойства и методы в парент и отнаследовать, стили тоже
    * */

    @Input() deposit: Deposit;
    private appService: AppService;

    constructor(
        appService: AppService
    ) {
        this.appService = appService;
    }

    ngOnInit() {
    }

    close() {
        this.appService.closeModal();
    }

    openLink() {
        window.open(this.deposit.link);
    }
}
