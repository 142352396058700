import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SearchFilterModalComponent } from './search-filter-modal';
import { MainPageModule } from 'src/app/core/page.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: '',
                component: SearchFilterModalComponent
            }
        ]),
        MainPageModule,
        SwiperModule,
        PipesModule
    ],
    exports: [
        SearchFilterModalComponent
    ],
    declarations: [
        SearchFilterModalComponent,
    ]
})
export class SearchFilterModalModule {

}
