import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {StateChange} from 'ng-lazyload-image';
import {LazyService} from '../../services/lazy.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'lazy-item',
    templateUrl: 'item.html'
})

export class LazyItemComponent implements OnInit {

    @Input() src: any;
    @Input() default: any;
    @Input() active = true;
    @Input() isActive = false;

    lazy: LazyService;
    sanitization: DomSanitizer;
    fromCache = false;
    customObservable: Observable<{ isIntersecting: boolean }>;

    constructor(
        lazy: LazyService,
        sanitization: DomSanitizer,
    ) {
        this.lazy = lazy;
        this.sanitization = sanitization;
    }

    ngOnInit() {
        if (this.isActive) {
            this.customObservable = new Observable<{ isIntersecting: boolean }>(observe => {
                observe.next({isIntersecting: true});
            });
        }
        if (this.lazy.oldIos) {
            this.active = false;
        }

        if (this.active) {
            if (this.lazy.isExist(this.src)) {
                this.fromCache = true;
            } else {

            }
        } else {
            this.fromCache = true;
        }

    }

    onStateChange($event: StateChange) {
        switch ($event.reason) {
            case 'setup':
                break;
            case 'observer-emit':
                break;
            case 'start-loading':
                break;
            case 'mount-image':
                break;
            case 'loading-succeeded':
                this.lazy.push(this.src);
                break;
            case 'loading-failed':
                break;
            case 'finally':
                break;
        }
    }

    getBackgroundImageCss(imageUrl: string) {
        return imageUrl ? this.sanitization.bypassSecurityTrustStyle(`url(assets/img/svg/${imageUrl})`) : null;
    }

}
