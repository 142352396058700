import {NgModule} from '@angular/core';
import {InputItemComponent} from './input/item.component';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SubmitItemComponent} from './submit/item.component';
import {CheckboxItemComponent} from './checkbox/item.component';
import { RadioItemComponent } from './radio/item.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
    declarations: [
        InputItemComponent,
        CheckboxItemComponent,
        RadioItemComponent,
        SubmitItemComponent
    ],
    imports: [
        IonicModule,
        FormsModule,
        CommonModule,
        NgxMaskModule
    ],
    exports: [
        InputItemComponent,
        CheckboxItemComponent,
        RadioItemComponent,
        SubmitItemComponent
    ]
})

export class FormComponentsModule {
}
