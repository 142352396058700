import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MainModal} from '../../core/main.Modal';
import {AppService} from '../../services/app.service';
import {CartService} from 'src/app/models/cart.service';
import {CatalogService} from 'src/app/models/catalog.service';
import {UserService} from '../../models/user.service';
import {GestureController} from '@ionic/angular';
import {ModalService} from '../../services/modal.service';
import Scrollbar from 'smooth-scrollbar';
import CustomScrollBarPlugin from '../../core/CustomScrollBarPlugin';
import {SearchPageService, SearchType} from '../../services/search-page.service';


@Component({
    selector: 'search-filter-modal',
    styleUrls: ['./search-filter-modal.scss'],
    templateUrl: 'search-filter-modal.html'
})
export class SearchFilterModalComponent extends MainModal implements OnInit, AfterViewInit, OnDestroy {
    BACK_DROP_OPACITY = 0.3;
    @ViewChild('scrollContainer', {static: false})
    scrollContainer;

    public appService: AppService;
    modalService: ModalService;
    protected gestureCtrl: GestureController;
    public userService: UserService;
    cartService: CartService;
    catalogService: CatalogService;
    private scrollBar: Scrollbar;
    public searchPageService: SearchPageService;
    public SearchType = SearchType;
    cdr: ChangeDetectorRef;


    constructor(
        cartService: CartService,
        catalogService: CatalogService,
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController,
        searchPageService: SearchPageService,
        cdr: ChangeDetectorRef,
    ) {
        super(appService, modalService, gestureCtrl);
        this.catalogService = catalogService;
        this.cartService = cartService;
        this.searchPageService = searchPageService;
        this.cdr = cdr;
    }

    ngOnInit() {
        setTimeout(() => {
            this.createVirtualScroll(this.scrollContainer.nativeElement);
        });
    }

    createVirtualScroll(targetElement) {
        Scrollbar.use(CustomScrollBarPlugin);
        this.scrollBar = Scrollbar.init(
            targetElement,
            {
                damping: 0.1,
                plugins: {
                    myPlugin: {
                        isModalGesture: () => this.modalService.isGesture
                    }
                }
            });
        this.scrollBar.addListener(this.onScroll.bind(this));
        this.onScroll({offset: {x: 0, y: 0}});
    }

    onScroll(e: any) {
        this.modalService.scrollModal(e.offset.y);
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
    }


    onClickButtonClose() {
        this.close();
    }

    changeSearchType(type: SearchType) {
        this.searchPageService.searchType = type;
    }

    getItemStatus(key: string) {
        return this.searchPageService.data[this.searchPageService.searchType].includes(key);
    }

    toggleItem(key: string) {
        const includeIndex = this.searchPageService.data[SearchType.Include].findIndex(i => i === key);
        const excludeIndex = this.searchPageService.data[SearchType.Exclude].findIndex(i => i === key);

        switch (this.searchPageService.searchType) {
            case SearchType.Include:
                if (excludeIndex > -1) {
                    this.searchPageService.data[SearchType.Exclude].splice(excludeIndex, 1);
                }
                if (includeIndex > -1) {
                    this.searchPageService.data[SearchType.Include].splice(includeIndex, 1);
                } else {
                    this.searchPageService.data[SearchType.Include].push(key);
                }
                break;
            case SearchType.Exclude:
                if (includeIndex > -1) {
                    this.searchPageService.data[SearchType.Include].splice(includeIndex, 1);
                }
                if (excludeIndex > -1) {
                    this.searchPageService.data[SearchType.Exclude].splice(excludeIndex, 1);
                } else {
                    this.searchPageService.data[SearchType.Exclude].push(key);
                }
                break;

        }
    }

    saveFilter() {
        this.close();
    }

    close() {
        super.close();
    }

    clearFilter() {
        this.searchPageService.data[SearchType.Include] = [];
        this.searchPageService.data[SearchType.Exclude] = [];
    }
}
