import {Pipe, PipeTransform} from '@angular/core';
import {Address} from 'src/app/entity/address.entity';

@Pipe({
    name: 'addressInline'
})
export class AddressInlinePipe implements PipeTransform {

    addressShorts = {
        street: '',
        house: 'д.',
        building: 'корпус',
        porch: 'под.',
        floor: 'эт.',
        office: 'кв.',
        intercom: 'домофон',
    };

    transform(address: Address, ...args: unknown[]): string {
        const addressParts: string[] = [];

        Object.keys(this.addressShorts).forEach(key => {
            if (this.addressShorts.hasOwnProperty(key) && address[key]) {
                addressParts.push(`${this.addressShorts[key]} ${address[key]}`);
            }
        });

        return addressParts.join(', ');
    }

}
