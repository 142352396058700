import {
    Injectable,
    Injector, NgZone
} from '@angular/core';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {AppService} from './app.service';
import {filter, first} from 'rxjs/operators';
import {CatalogService} from '../models/catalog.service';
import {NavigationService} from './navigation.service';
import {ProductModalService} from './product-modal.service';
import {BranchService} from '../models/branch.service';
import {CartService} from '../models/cart.service';
import {WokService} from '../models/wok.service';
import {PizzaService} from '../models/pizza.service';
import {UserService} from '../models/user.service';
import {PromoModalComponent} from '../components/promo-modal/promo-modal';
import {WokModalComponent} from '../components/wok-constructor-modal/wok-modal';
import {PizzaModalComponent} from '../components/pizza-constructor-modal/pizza-modal';
import {PopupSelectAddressComponent} from '../components/popups/popup-select-address/popup-select-address';
import {OrderStep} from '../entity/cart.entity';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {ProductModalComponent} from '../components/product-modal/product-modal';
import {PopupSelectCityComponent} from '../components/popups/popup-select-city/popup-select-city';

// for all APP, CATALOG
// https://www.hatimaki.ru/catalog/{catalog_slug}
// https://www.hatimaki.ru/catalog/{catalog_slug}/{product_slug}/
// https://www.hatimaki.ru/wok-konstruktor/
// https://www.hatimaki.ru/pizza-konstruktor/
// https://www.hatimaki.ru/favorite/
// https://www.hatimaki.ru/contacts/
// https://www.hatimaki.ru/contacts/{branch_slug}/ + BRANCHES
// https://www.hatimaki.ru/dostavka/
// https://www.hatimaki.ru/about/
// https://www.hatimaki.ru/actions
// https://www.hatimaki.ru/actions/{promo_slug}/
// https://www.hatimaki.ru/cart/

enum RouteType {
    Menu,
    Category,
    WokConstructor,
    PizzaConstructor,
    Favorite,
    Contacts,
    Branch,
    Delivery,
    About,
    Actions,
    Promo,
    Cart,
}

interface Url {
    host: string; // host: "www.hatimaki.ru"
    path: string; // path: "/catalog/pizza/"
    scheme: string; // scheme: "https"
    url: string; // url: "https://www.hatimaki.ru/catalog/pizza/"
}

@Injectable({
    providedIn: 'root'
})
export class DeepLinkService {
    private readonly injector: Injector;
    private ngZone: any;
    public deepLinks: Deeplinks;
    private appService: AppService;
    private catalogService: CatalogService;
    private navigationService: NavigationService;
    private userService: UserService;
    private branchService: BranchService;
    private cartService: CartService;
    private pizzaService: PizzaService;
    private wokService: WokService;
    private iab: InAppBrowser;


    private url: Url = {host: null, path: null, scheme: null, url: null};
    private routeType: RouteType = null;

    constructor(
        ngZone: NgZone,
        injector: Injector,
        deepLinks: Deeplinks,
        iab: InAppBrowser,
    ) {
        this.ngZone = ngZone;
        this.injector = injector;
        this.deepLinks = deepLinks;
        this.iab = iab;
        this.appService = this.injector.get(AppService);
        this.catalogService = this.injector.get(CatalogService);
        this.navigationService = this.injector.get(NavigationService);
        this.userService = this.injector.get(UserService);
        this.branchService = this.injector.get(BranchService);
        this.cartService = this.injector.get(CartService);
        this.pizzaService = this.injector.get(PizzaService);
        this.wokService = this.injector.get(WokService);

        this.appService.getWindow()['deepLinkService'] = this;

        this.appService.platform.ready().then(() => {

            this.appService.getWindow().IonicDeeplink?.route({}, (match) => {
                    this.openDeepLink(match.$link);
                }, (noMatch) => {
                    this.openDeepLink(noMatch.$link);
                }
            );
            document.addEventListener('click', (event: any) => {
                const target = event.target.closest('a');
                if (target) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.openInnerLink(target.href);
                }
            });
        });

    }

    openInnerLink(href) {
        let eventUrl: URL = null;
        try {
            eventUrl = new URL(href);
        } catch (e) {
            return;
        }
        const protocol = eventUrl.protocol?.length && eventUrl.protocol[eventUrl.protocol?.length - 1] === ':' ?
            eventUrl.protocol.slice(0, -1) :
            eventUrl.protocol;
        const url: Url = {
            host: eventUrl.host,
            path: eventUrl.pathname,
            scheme: protocol,
            url: eventUrl.href,
        };
        // хостнеймы google и apple нужны, чтобы корректно открывалась ссылка на экране обновления приложения.
        if (['www.hatimaki.ru', 'ryazan.hatimaki.ru', 'hatimaki.ru', 'play.google.com', 'apps.apple.com'].includes(url.host)) {
            this.openDeepLink(url);
        } else {
            this.openInAppBrowser();
        }
    }

    openDeepLink(url: Url) {
        this.setUrl(url);
        this.routeType = this.getRoute();
        if (this.routeType === null && url.host !== 'hatimaki.page.link') {
            this.openInAppBrowser();
            return;
        }
        this.getRouteReady()
            .then(() => {
                this.startRoute().then(() => {
                });
            })
            .catch((err) => {
                console.log('DeepLinkService.getRouteReady reject: not all Promises resolved', err);
            });
    }

    private setUrl(url) {
        this.url = url;
        let path = this.url.path;
        path = path?.length && path[0] === '/' ? path.substring(1) : path;
        path = path?.length && path[path.length - 1] === '/' ? path.slice(0, -1) : path;
        this.url.path = path;
        let search: string = null;
        try {
            const urlObject = new URL(this.url.url);
            if (urlObject?.search) {
                search = urlObject.search;
            }
        } catch (e) {
            console.warn('error get utm data');
        }
        if (search) {
            if (search.length && search[0] === '?') {
                search = search.slice(1);
            }
            localStorage.setItem('com.ionicframework.hatimaki__deepLinkUTMData', search);
            localStorage.setItem('com.ionicframework.hatimaki__deepLinkUTMTimeExpired', (Date.now() + 86400000).toString());
        }
    }

    private getRoute(): RouteType {

        const regexpList: { [key in RouteType]?: RegExp } = {
            [RouteType.Category]: /^catalog\/.+$/,
            [RouteType.WokConstructor]: /^wok-konstruktor$/,
            [RouteType.PizzaConstructor]: /^pizza-konstruktor$/,
            [RouteType.Favorite]: /^favorite$/,
            [RouteType.Contacts]: /^contacts$/,
            [RouteType.Branch]: /^contacts\/.+$/,
            [RouteType.Delivery]: /^dostavka$/,
            [RouteType.About]: /^about$/,
            [RouteType.Actions]: /^actions$/,
            [RouteType.Promo]: /^actions\/.+$/,
            [RouteType.Cart]: /^cart$/,
        };
        for (const key in regexpList) {
            if (regexpList.hasOwnProperty(key) && regexpList[key].test(this.url.path)) {
                return Number(key);
            }
        }
        return null;
    }

    private getRouteReady(): Promise<any> {
        const getAppReady = () => this.appService.appReadySubject.pipe(filter(v => v), first()).toPromise();
        const getAuthReady = () => {
            return new Promise((resolve) => {
                const authRoutes = [
                    // RouteType.Category,
                    // RouteType.WokConstructor,
                    // RouteType.PizzaConstructor,
                    RouteType.Favorite,
                    // RouteType.Contacts,
                    // RouteType.Branch,
                    // RouteType.Delivery,
                    // RouteType.About,
                    // RouteType.Actions,
                    // RouteType.Promo,
                    RouteType.Cart,
                ];
                if (authRoutes.includes(this.routeType)) {
                    this.userService.userSubject.pipe(filter(v => v), first()).toPromise().then(() => {
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        };
        const getMenuReady = () => this.appService.menuReadySubject.pipe(filter(v => v), first()).toPromise();
        const getCheckCity = () => {
            return new Promise((resolve, reject) => {
                const targetCity: string = this.url.host.includes('ryazan.hatimaki.ru') ? '1' : '0';
                const currentCity = this.appService.getCityId();

                // если выбран текущий город, то завершаем promise
                if (targetCity === currentCity) {
                    resolve();
                    return;
                }
                // если выбран другой город, то пробуем менять его
                this.changeCity(targetCity)
                    .then(() => resolve())
                    .catch(() => {
                        // произошла ошибка смены города, возвращаем текущий город
                        this.changeCity(currentCity)
                            .finally(() => reject());
                    });

            });
        };
        return new Promise(((resolve, reject) => {
            getAppReady()
                .then(getAuthReady)
                .then(getCheckCity)
                .then(getMenuReady)
                .then(() => {
                    resolve();
                })
                .catch((e) => {
                    console.log('reject', e);
                    reject(e);
                });
        }));
    }

    public changeCity(targetCity) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => {
                const closeAllowedByExternal = !!this.userService.defaultAddress;
                this.appService.closeModal();
                localStorage.setItem('com.ionicframework.hatimaki__city', targetCity);
                this.userService.user.city = Number(targetCity);
                this.userService.getDefaultAddress();
                this.appService.getCity(needChangeCity => {});
                this.appService.menuReady = false;
                this.branchService.data.length = 0;
                this.catalogService.clearSelectedSideDish();
                this.pizzaService.clear();
                this.wokService.clear();
                this.navigationService.goToPage('menu', true);
                this.userService.getUser(() => {
                    if (this.userService.defaultAddress) {
                        this.catalogService.getCatalog(status => {
                            if (status) {
                                this.appService.changeMenu();
                                resolve();
                            }
                        }, this.userService.token, true);
                    } else {
                        this.appService.openModal(PopupSelectCityComponent, {}, (callback) => {
                            if (callback) {
                                this.appService.changeMenu();
                                resolve();
                            } else {
                                resolve();
                            }
                        }, false);
                    }
                    this.cartService.sync(OrderStep.Start);
                });
            });

        });
    }

    private startRoute() {
        return new Promise((resolve => {
            this.ngZone.run(() => {
                this.appService.closeModal();
                this.appService.modalService.close();

                switch (this.routeType) {
                    case RouteType.Category: {
                        const path = this.url.path.split('/');

                        const categorySlug = path[1];
                        const subCategorySlug = path.length > 1 ? path[2] : null;
                        const productSlug = path[path.length - 1];

                        const category = this.catalogService.categories.find(item => item.slug === categorySlug);
                        const subCategory = subCategorySlug ? category?.subCategories?.find(item => item.slug === subCategorySlug) : null;
                        const product = category ? this.catalogService.mainProductArray.find(item => item.slug === productSlug) : null;

                        if (category) {
                            if (subCategory) {
                                this.navigationService.goToPage('category/' + category.id + '/' + subCategory.id);
                            } else {
                                this.navigationService.goToPage('category/' + category.id);
                            }
                            if (product) {
                                const productModalService = this.injector.get(ProductModalService);
                                productModalService.openProductModal(ProductModalComponent, product);
                            }
                        }
                        resolve();
                        break;
                    }
                    case RouteType.WokConstructor: {
                        const wokConstructorProduct = this.catalogService.mainProductArray.find(i => i.isWok);
                        if (wokConstructorProduct) {
                            this.navigationService.goToPage('category/' + wokConstructorProduct.categoryId);
                            this.appService.modalService.createModal(WokModalComponent);
                        } else {
                            this.openInAppBrowser();
                        }
                        resolve();
                        break;
                    }
                    case RouteType.PizzaConstructor: {
                        const pizzaConstructorProduct = this.catalogService.mainProductArray.find(i => i.isPizza);
                        if (pizzaConstructorProduct) {
                            this.navigationService.goToPage('category/' + pizzaConstructorProduct.categoryId);
                            this.appService.modalService.createModal(PizzaModalComponent);
                        } else {
                            this.openInAppBrowser();
                        }
                        resolve();
                        break;
                    }
                    case RouteType.Favorite: {
                        this.navigationService.goToPage('favorite');
                        resolve();
                        break;
                    }
                    case RouteType.Contacts: {
                        this.navigationService.goToPage('branches');
                        resolve();
                        break;
                    }
                    case RouteType.Branch: {
                        this.navigationService.goToPage('branches');
                        this.branchService.isDataSubject.pipe(filter(v => v), first()).toPromise().then(() => {
                            const path = this.url.path.split('/');
                            const branchSlug = path[1];
                            const branch = this.branchService.data.find(i => i.slug === branchSlug);
                            if (branch) {
                                this.navigationService.goToPage('branches/' + branch.id);
                            } else {
                                this.openInAppBrowser();
                            }
                            resolve();
                        });
                        break;
                    }
                    case RouteType.Delivery: {
                        this.navigationService.goToPage('delivery');
                        resolve();
                        break;
                    }
                    case RouteType.About: {
                        this.navigationService.goToPage('about_us');
                        resolve();
                        break;
                    }
                    case RouteType.Actions: {
                        this.navigationService.goToPage('promos');
                        resolve();
                        break;
                    }
                    case RouteType.Promo: {
                        const path = this.url.path.split('/');
                        const promoSlug = path[1];
                        const promo = this.catalogService.promos.find(i => i.slug === promoSlug);
                        if (promo) {
                            this.navigationService.goToPage('promos');
                            this.appService.modalService.createModal(PromoModalComponent, {promoId: promo.id});
                        } else {
                            this.openInAppBrowser();
                        }
                        resolve();
                        break;
                    }
                    case RouteType.Cart: {
                        this.navigationService.goToPage('cart');
                        resolve();
                        break;
                    }
                    default:
                        this.navigationService.goToPage('menu', true);
                        resolve();
                        break;
                }
            });
        }));
    }

    private openInAppBrowser() {
        if (this.url?.url) {
            this.appService.openLink(this.url.url);
        }
    }
}
