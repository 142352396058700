import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';

@Injectable({
    providedIn: 'root',
})

export class InfoService extends MainService {

    public HatimakiVersion: any;

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    loadVersion(callback: (data: any) => void) {
        this.getRequest({}, (data) => {
            this.HatimakiVersion = data;
            callback(data.result);
        }, 'get_version.php');
    }

}
