import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export enum CancelHttpType {
    Cart,
    SearchFilter
}

@Injectable({
    providedIn: 'root'
})
/* класс для управления отменяемыми запросами
   если требуется выполнять повторяющиеся запросы, при этом отменяя устаревшие
   Для добавления в стек запроса используется функция add передав в нее тип запроса
   Для отмены всех запросов стека ннужно вызвать метод cancel, передав тип запроса, который требуется очистить
*/
export class CancelHttpService {
    stack = {};

    constructor() {
    }

    add(type: CancelHttpType) {
        if (typeof this.stack[type] === 'undefined') {
            this.stack[type] = new Subject<void>();
        }
        return this.stack[type].asObservable();
    }

    cancel(type) {
        this.stack[type]?.next();
    }
}
