import {Component, OnInit} from '@angular/core';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-souses',
    templateUrl: './popup-souses.component.html',
    styleUrls: ['./popup-souses.component.scss'],
})
export class PopupSousesComponent implements OnInit {
    private appService: AppService;
    text: any = 'text from server';
    textIsOpen = false;

    constructor(
        appService: AppService
    ) {
        this.appService = appService;
    }

    ngOnInit() {
    }

    close() {
        this.appService.closeModal();
    }

    buy() {
        this.appService.closeModal({action: 'buy'});
    }

    onToggleButton() {
        this.textIsOpen = !this.textIsOpen;
    }
}
