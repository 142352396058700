import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {AppService} from 'src/app/services/app.service';

@Component({
    selector: 'app-popup-error',
    templateUrl: './popup-error.component.html',
    styleUrls: ['./popup-error.component.scss'],
})
export class PopupErrorComponent implements OnInit, OnDestroy {

    public appService: AppService;
    @Input() text = '';
    @Input() link = '';
    @Input() phone = '';
    @Input() internetError = false;

    constructor(
        appService: AppService,
    ) {
        this.appService = appService;
    }

    ngOnInit() {
        this.appService.postError = true;
    }

    openLink() {
        window.open(this.link);
    }

    close() {
        if (!this.appService.isLoading && !this.internetError) {
            this.appService.closeModal({action: 'close'});
        }
    }

    restartRequest() {
        // if (navigator.onLine) {
        //     this.appService.hideLoading();
        // } else {
        //     this.appService.showLoading();
        // }
        this.appService.showLoading();
        this.appService.httpError = true;
        this.close();
    }

    ngOnDestroy() {
        this.appService.postError = false;
    }
}
