import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

    @Input() slug = '';
    @Input() img = '';
    @Input() title = '';
    @Input() text = '';
    @Input() showClose: boolean;

    hidden = true;

    constructor() {
    }

    ngOnInit() {
        setTimeout(() => {
            this.hidden = false;
        });
    }

    closeNotification() {
        this.hidden = true;
    }
}
