import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {AppService} from 'src/app/services/app.service';
import {UserService} from 'src/app/models/user.service';
import {DeliveryType, OrderStep} from 'src/app/entity/cart.entity';
import {BranchService} from '../../../models/branch.service';
import {SuggestionsService} from '../../../models/suggestions.service';
import {DeliveryService} from '../../../models/delivery.service';
import {Subscription} from 'rxjs';
import {CatalogService} from '../../../models/catalog.service';
import {PizzaService} from '../../../models/pizza.service';
import {WokService} from '../../../models/wok.service';
import {CartService} from '../../../models/cart.service';
import {AddressService} from '../../../models/address.service';

@Component({
    selector: 'app-popup-select-city',
    templateUrl: 'popup-select-city.html',
    styleUrls: ['popup-select-city.scss']
})
export class PopupSelectCityComponent implements OnInit, OnDestroy {
    catalogService: CatalogService;

    constructor(
        private appService: AppService,
        private suggestionsService: SuggestionsService,
        private deliveryService: DeliveryService,
        public branchService: BranchService,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
        catalogService: CatalogService,
        private pizzaService: PizzaService,
        private wokService: WokService,
        private cartService: CartService,
        public addressService: AddressService,
    ) {
        this.catalogService = catalogService;
    }

    pageReady = true;
    isOpenCityList = false;
    DeliveryType = DeliveryType;
    addressSuggestions: any[] = [];

    cityList = ['Москва и МО', 'Рязань'];
    selectedCityIndex = 0;
    private closeSubscription: Subscription = null;

    hideSplashWhenReady = false;

    ngOnInit() {
        this.selectedCityIndex = 0;
    }

    ionViewWillEnter() {
        // блокируем физ. кнопку назад
        if (!this.userService.defaultAddress) {
            this.appService.blockBack = true;
        }

        if (this.hideSplashWhenReady) {
            this.appService.hideSplash();
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    close() {
        // console.log('close');
        this.appService.blockBack = false;
        this.appService.closeModal();
    }

    ngOnDestroy() {
        if (this.closeSubscription) {
            this.closeSubscription.unsubscribe();
            this.closeSubscription = null;
        }
    }

    selectCity(city: number) {
        this.selectedCityIndex = city;
        this.isOpenCityList = false;

        if (this.appService.getCityId() === city.toString()) {
            return;
        }

        // выбрали другой город
        localStorage.setItem('com.ionicframework.hatimaki__city', city.toString());
        localStorage.setItem('manualCitySelect', city.toString());  // Сохраняем флаг, что город выбран вручную

        this.userService.user.city = city;
        this.userService.getDefaultAddress();
        this.appService.getCity(needChangeCity => {});
        this.appService.menuReady = false;
        this.branchService.data.length = 0;
        this.catalogService.clearSelectedSideDish();
        this.pizzaService.clear();
        this.wokService.clear();

        this.userService.getUser(() => {
            this.cartService.sync(OrderStep.Start);
        });
    }
}
