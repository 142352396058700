import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {AppService} from '../services/app.service';
import {OrdersDataFromFoquz} from '../entity/order.entity';
import {PopupLoadErrorSurvey} from '../components/popups/popup-loaderror-survey/popup-loaderror-survey.component';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {Subscription} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class SurveyService extends MainService {

    baseUrl = 'https://hatimaki.foquz.ru';
    apiUrl = '';
    surveyToken = '30ZKhybu_U1MLi0';

    private iab: InAppBrowser;

    public ordersWithSurvey: OrdersDataFromFoquz[] = [];
    public completedSurvey: OrdersDataFromFoquz[] = [];
    public notCompletedSurvey: OrdersDataFromFoquz[] = [];
    public ordersStatistic: any;
    // TODO HATIMAKI-843 HATIMAKI-788 в счетчике ordersWithSurveyCount больше нет необходимости (на момент выполнения таска).
    public ordersWithSurveyCount = '0';
    private subscriptions: Subscription[] = [];

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        iab: InAppBrowser,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
        this.iab = iab;
    }

    getSurveyData(userPhone: string, link: string, callback: (res: any) => void, isLoading = true, callbackError: () => void | null = null) {
        // userPhone = '79852081252';

        const data = {
            'access-token': this.surveyToken,
            phone: userPhone
        };

        this.getRequest(data, callback, link, false, isLoading, callbackError);
    }

    getSurveyCount(userPhone: string, callback: (status: boolean) => void, callbackError: () => void | null = null) {
        const link = `${this.baseUrl}/foquz/api/clients/not-done-answers-count`;
        this.getSurveyData(userPhone, link, res => {
            this.ordersWithSurveyCount = res.count;
            callback(true);
        }, false, callbackError);
    }

    getOrdersWithSurvey(userPhone: string, callback: (status: boolean) => void, callbackError: () => void | null = null) {
        const link = `${this.baseUrl}/foquz/api/clients/answer-with-orders`;
        this.getSurveyData(userPhone, link, res => {
            this.ordersWithSurvey = res.items;
            this.getCompletedSurvey();
            this.getNotCompletedSurvey();
            callback(true);
        }, true, () => {
            this.ordersWithSurvey = [];
            this.getCompletedSurvey();
            this.getNotCompletedSurvey();

            callbackError();
        });
    }

    getOrderStatistic(userPhone: string, callback: (status: boolean) => void, callbackError: () => void | null = null) {
        const link = `${this.baseUrl}/foquz/api/clients/dish-ratings`;
        this.getSurveyData(userPhone, link, res => {
            this.ordersStatistic = res;
            callback(true);
        }, true, callbackError);
    }

    getCompletedSurvey() {
        this.completedSurvey = this.ordersWithSurvey.filter(order => order.status === 'done');
    }

    getNotCompletedSurvey() {
        this.notCompletedSurvey = this.ordersWithSurvey.filter(order => {
            if (['open', 'in-progress', 'new'].includes(order.status)) {
                return true;
            }
        });
    }

    openSurvey(orderIiko: number) {
        this.showLoading();

        const order = this.ordersWithSurvey.find(o => o.iiko_order_number === orderIiko.toString());
        const orderSurveyLink = order.link;

        const url = `https://${orderSurveyLink}`;
        const options = 'location=no,fullscreen=no,hidden=yes,hardwareback=no,toolbar=no';

        const browser = this.iab.create(url, '_blank', options);

        const subscribeToLoadBrowser = browser.on('loadstop').subscribe(evt => {
            this.hideLoading();

            const iabScript = `
                const head = document.querySelector('head');
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('name', 'viewport');
                metaTag.setAttribute('content', 'viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no');
                head.append(metaTag);

                const body = document.querySelector('body');
                const surveyDiv = document.querySelector('.survey');
                const surveyHeaderDiv = document.querySelector('.survey__header');
                const customHeader = document.createElement('div');
                const backButton = document.createElement('a');
                const buttonArrow = document.createElement('span');

                surveyHeaderDiv.setAttribute('style', 'display:none!important');
                backButton.classList.add('back-btn');
                buttonArrow.classList.add('custom-arrow');
                // buttonArrow.innerHTML = '&#8592;';
                backButton.append(buttonArrow);
                customHeader.classList.add('custom-header');
                customHeader.innerHTML = 'Опросы';
                customHeader.append(backButton);
                body.insertBefore(customHeader, surveyDiv);
                backButton.addEventListener('click', () => {
                    webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({"close": true}));
                    // window.location = 'http://www.hatimaki.foquz.ru/close-iab.htm#close-iab';
                });
            `;

            const iabStyle = `
                body {
                    width: 100%;
                    overflow: hidden;
                    background: #222429;
                }

                .custom-header {
                    width: 100%;
                    height: 44px !important;
                    justify-content: center;
                    position: relative;
                    line-height: 44px;
                    text-align: center;
                    font-size: 16px;
                    background: #222429 !important;
                    color: #fff;
                }

                .back-btn {
                    height: 26px;
                    width: 26px;
                    position: absolute;
                    top: 50%;
                    left: 16px;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .survey {
                    min-height: calc(100% - 44px);
                    height: calc(100% - 44px);
                    border-radius: 10px 10px 0 0;
                    overflow-x: hidden;
                    overflow-y: auto;
                }

                .custom-arrow {
                    position: relative;
                    display: block;
                    width: 15px;
                    height: 1px;
                    background: #000;
                }

                .custom-arrow::before,
                .custom-arrow::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    display: block;
                    width: 6px;
                    height: 1px;
                    transform-origin: left;
                    background: #000;
                }

                .custom-arrow::before {
                    transform: rotate(45deg);
                }

                .custom-arrow::after {
                    transform: rotate(-45deg);
                }
            `;

            browser.executeScript({
                code: iabScript
            }).then(() => {
                browser.show();
            });

            browser.insertCSS({
                code: iabStyle
            });

            subscribeToLoadBrowser.unsubscribe();
        });

        this.subscriptions.push(browser.on('loaderror').subscribe(params => {

            if (params.code) {
                this.hideLoading();
                this.appService.openModal(PopupLoadErrorSurvey, {text: 'При загрузке сервиса опросов произошла ошибка.</br>Попробуйте пройти опрос позднее'}, (data) => {
                    if (data) {
                        browser.close();
                    }
                });
            }
        }));

        this.subscriptions.push(browser.on('message').subscribe(evt => {
            if (evt.data.close) {
                this.ordersWithSurvey.length = 0;
                browser.close();
            }
        }));

        this.subscriptions.push(browser.on('exit').subscribe(() => {
            this.subscriptions.forEach(sub => sub.unsubscribe());
            this.subscriptions.length = 0;
        }));
    }
}
