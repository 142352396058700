import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from './app.service';
import {ApiResponse} from '../entity/apiResponse.entity';

export enum SearchType {
    Include = 'include',
    Exclude = 'exclude',
}

@Injectable({
    providedIn: 'root'
})
export class SearchPageService extends MainService {
    useNewApi = true;
    searchType: SearchType;

    data = {
        search: '',
        [SearchType.Include]: [],
        [SearchType.Exclude]: [],
    };
    private request: Promise<void>;

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
        this.searchType = SearchType.Include;
    }

    public searchDishes = [];
    public searchingText: string;


    saveSearchData(resultSearchDishes, searchingText) {
        this.searchDishes = resultSearchDishes;
        this.searchingText = searchingText;
    }

    getSearchData() {
        return {
            searchDishes: this.searchDishes,
            searchingText: this.searchingText
        };
    }

    search(callback: (result: any[] | null) => void) {
        this.postRequest(this.data, (response: ApiResponse) => {
            callback(response.result);
        }, 'catalog/filter', false, false);
    }
}
