import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';


import {LazyLoadImageModule} from 'ng-lazyload-image';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { MainPageModule } from 'src/app/core/page.module';
import { WokModalComponent } from './wok-modal';
import { FormComponentsModule } from '../form/form-components.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FormComponentsModule,
        RouterModule.forChild([
            {
                path: '',
                component: WokModalComponent
            }
        ]),
        LazyLoadImageModule,
        MainPageModule,
        SwiperModule
    ],
    exports: [
        WokModalComponent
    ],
    declarations: [
        WokModalComponent
    ]
})
export class WokModalModule {

}
