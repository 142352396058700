import {ApplicationRef, ComponentFactoryResolver, EventEmitter, Injectable} from '@angular/core';
import {
    ActionSheetController,
    AlertController,
    LoadingController,
    MenuController,
    ModalController,
    NavController,
    PickerController,
    Platform
} from '@ionic/angular';
import {SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {popupEnterAnimation, popupLeaveAnimation} from '../core/animations/popup.animations';
import {Cities} from '../entity/lists';
import {NetworkInterface} from '@ionic-native/network-interface/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {PickerColumnOption, PickerOptions} from '@ionic/core';
import {ProductOffer} from '../entity/productOffer.entity';
import {BehaviorSubject, Subject} from 'rxjs';
import {AnalyticsService} from './analytics.service';
import {ModalService} from './modal.service';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {environment} from '../../environments/environment';
import {FirebasePlugin} from 'cordova-plugin-firebasex';
import * as StackTrace from 'stacktrace-js';


export enum FirebaseTraces {
    menuReady = 'menuReady',
}

function _window(): any {
    return window;
}

@Injectable({
    providedIn: 'root',
})
export class AppService {
    appReadySubject = new BehaviorSubject(false);

    get appReady(): boolean {
        return this.appReadySubject.value;
    }

    set appReady(value) {
        console.log('load app ready', value);
        if (value !== this.appReady) {
            this.appReadySubject.next(value);
        }
    }

    menuReadySubject = new BehaviorSubject(false);

    get menuReady(): boolean {
        return this.menuReadySubject.value;
    }

    set menuReady(value) {
        if (value !== this.menuReady) {
            this.menuReadySubject.next(value);
        }
    }

    menuType: string | 'list' | 'grid';

    hideFooterMenu = false;
    locationFooterMenu = 0;
    footerMenuNoAnimation = true;

    rootPage: any = false;
    canGoBack = false;

    platform: Platform;
    nav: NavController;
    menu: MenuController;
    splashScreen: SplashScreen;
    statusBar: StatusBar;
    firebasex: FirebaseX;
    alertController: AlertController;
    appVersion: AppVersion;
    cdr: ApplicationRef;
    modalController: ModalController;
    pickerCtrl: PickerController;
    modalService: ModalService;
    actionSheetController: ActionSheetController;
    camera: Camera;
    factory: ComponentFactoryResolver;
    ymapsLoaded = false;
    phone = '+74951204423';
    public appVersionNumber: string;
    deviceId: string;
    windowHeight: number;
    isMenuChanged = false;
    workTimeIsShowed = false;
    birthdayIsShowed = false;
    public isLoading: boolean;
    public loadingCounter = 0;
    loaderController: LoadingController;
    eventCloseProductModal: Subject<any> = new Subject<any>();
    eventCloseDefaultAddressModal: Subject<any> = new Subject<any>();
    eventClosePopupMessageModal: Subject<any> = new Subject<any>();
    productModalIsOpened = false;
    isOpenModal = false;    // === true, если открыто хотя бы одно модальное окно
    eventResetMenuSwiper: Subject<any> = new Subject<any>();
    resetMenuSwiper$ = this.eventResetMenuSwiper.asObservable();
    blockBack = false;
    blockSwipeBack = false;

    postError = false;
    httpError = false;
    postErrorList = 0;
    updateCatalog:
        {
            emitter: EventEmitter<null>,
            startTimer: any,
            stopTimer: any
        } = {
        startTimer: null,
        stopTimer: null,
        emitter: new EventEmitter<null>()
    };
    showMenuObject = {
        opacity: 1
    };
    eventChangeMenu: Subject<any> = new Subject<any>();
    changeMenu$ = this.eventChangeMenu.asObservable();
    onForward = true;
    startLaunch = new Date();
    endLaunch = new Date();
    userPhone = '';
    prevPhone = '';
    protected loading: any;
    private socialSharing: SocialSharing;
    private modalWindow: HTMLIonModalElement[] = [];
    private alertWindow: any;
    private networkInterface: NetworkInterface;
    private city: string;
    splashImg = true;
    coords: [number, number] = null;
    needChangeAddress = false;
    needReturnToLocation = false;
    locationChangedModalWasOpen = false;
    bigFontsize = false;

    constructor(
        platform: Platform,
        menu: MenuController,
        splashScreen: SplashScreen,
        statusBar: StatusBar,
        firebasex: FirebaseX,
        alertController: AlertController,
        modalController: ModalController,
        loaderController: LoadingController,
        cdr: ApplicationRef,
        appVersion: AppVersion,
        networkInterface: NetworkInterface,
        socialSharing: SocialSharing,
        pickerCtrl: PickerController,
        modalService: ModalService,
        actionSheetController: ActionSheetController,
        camera: Camera,
        factory: ComponentFactoryResolver
    ) {

        this.platform = platform;
        this.menu = menu;
        this.splashScreen = splashScreen;
        this.firebasex = firebasex;
        this.alertController = alertController;
        this.modalController = modalController;
        this.cdr = cdr;
        this.appVersion = appVersion;
        this.statusBar = statusBar;
        this.networkInterface = networkInterface;
        this.socialSharing = socialSharing;
        this.pickerCtrl = pickerCtrl;
        this.loaderController = loaderController;
        this.modalService = modalService;
        this.actionSheetController = actionSheetController;
        this.camera = camera;
        this.factory = factory;
        this.modalService.createModalEmit.subscribe(() => {
            this.updateStatusBar();
        });

        const menuType = localStorage.getItem('com.ionicframework.hatimaki__menuType');
        this.menuType = menuType || 'list';
        this.getWindow()['appService'] = this;
    }

    public get cityName(): string {
        return this.city;
    }

    public set cityName(value: string) {
        this.city = value;
    }

    changeMenu() {
        this.eventChangeMenu.next();
    }

    public cleanCdr() {
        this.cdr.tick();
    }

    public getCityId() {
        return localStorage.getItem('com.ionicframework.hatimaki__city');
    }

    async getCity(callback?) {
        let city = localStorage.getItem('com.ionicframework.hatimaki__city');
        const manualCitySelect = localStorage.getItem('manualCitySelect');

        // Проверяем, был ли город выбран вручную
        if (manualCitySelect) {
            this.cityName = manualCitySelect === Cities.Ryazan.toString() ? 'Рязань' : 'Москва и МО';
            callback(false);
            return;  // Если город выбран вручную, выходим из метода
        }

        // Определяем город по координатам, если пользователь дал разрешение
        if (this.coords) {
            const coords = this.coords;

            // Проверяем, попадают ли координаты в Рязань
            if (this.isRyazan(coords)) {
                city = Cities.Ryazan.toString();
                this.cityName = 'Рязань';
            } else {
                // Если не в Рязани, по умолчанию устанавливаем Москву
                city = Cities.Moscow.toString();
                this.cityName = 'Москва и МО';
            }

            // Сохраняем город в localStorage
            localStorage.setItem('com.ionicframework.hatimaki__city', city);
            callback(false);
        } else {
            // Если координаты недоступны, проверяем локальное хранилище
            if (+city === Cities.Ryazan) {
                this.cityName = 'Рязань';
                callback(false);
            } else if (city === null || city === 'undefined') {
                // если город ещё не установлен, то возвращаем true,
                // чтобы вызвать попап с выбором города
                callback(true);
            } else {
                this.cityName = 'Москва и МО';
                callback(false);
            }
        }
    }

    // Функция для проверки, что координаты принадлежат Рязани
    isRyazan(coords: [number, number]): boolean {
        // Пример координат Рязани (средний центр)
        const ryazanBounds = {
            latMin: 54.5760,  // Минимальная широта Рязани
            latMax: 54.7776,  // Максимальная широта Рязани
            lonMin: 39.6017,  // Минимальная долгота Рязани
            lonMax: 39.8720   // Максимальная долгота Рязани
        };

        const lat = coords[0];
        const lon = coords[1];

        return lat >= ryazanBounds.latMin && lat <= ryazanBounds.latMax &&
            lon >= ryazanBounds.lonMin && lon <= ryazanBounds.lonMax;
    }

    updateStatusBar() {
        this.statusBar.overlaysWebView(true);
        this.statusBar.overlaysWebView(false);
    }

    setStatusBar() {
        this.statusBar.backgroundColorByHexString('#222429');
        this.statusBar.styleBlackOpaque();
    }

    isIos() {
        return this.platform.is('ios');
    }

    isAndroid() {
        return this.platform.is('android');
    }

    isCordova() {
        return this.platform.is('cordova');
    }

    isDevice() {
        return this.isCordova() && (this.isAndroid() || this.isIos());
    }

    getCordova() {
        if (this.isDevice()) {
            return this.getWindow().cordova;
        }

        return null;
    }

    getWindow() {
        return _window();
    }

    hideSplash() {
        this.splashScreen.hide();
        this.splashImg = false;
    }

    showSplash() {
        this.splashScreen.show();
        this.splashImg = true;
    }

    getScreenHeight() {
        return this.platform.height();
    }

    openMenu() {
        this.menu.open();
    }

    closeMenu() {
        this.menu.close();
    }

    callPhone(phone: string | null = null) {
        this.openLink('tel:' + this.phone);
    }

    openLink(link) {
        if (this.isCordova()) {
            this.getCordova().InAppBrowser.open(link, '_system', 'location=yes');
        } else {
            window.open(link, '_system', 'location=yes');
        }
    }

    public back() {

        if (this.modalService.isModal) {
            this.modalService.close();
            return false;
        }

        if (this.isOpenModal) {
            this.closeModal();
            return false;
        }

        if (this.isLoading) {
            return false;
        }

        if (this.blockBack) {
            return false;
        }

        if (this.alertWindow) {
            this.alertWindow.dismiss();
            return false;
        }

        if (this.modalWindow.length) {
            this.closeModal();
            return false;
        }

        return true;
    }

    closeProductModal() {
        this.eventCloseProductModal.next();
    }

    closeDefaultAddressModal() {
        this.eventCloseDefaultAddressModal.next();
    }

    closePopupMessageModal() {
        this.eventClosePopupMessageModal.next();
    }

    public isAuth() {
        const userToken = this.getUserToken();
        return userToken !== 'null' && userToken !== 'undefined' && userToken;
    }

    public showFooter(show = true) {

    }

    public setActiveFooter(index) {
        this.locationFooterMenu = index;
    }

    logError(data: {
        title: string,
        type: string,
        data?: { [key: string]: string },
        error?: any
    }) {
        data.data.prodMode = environment.production ? 'production' : 'debug';
        const firebasePlugin: FirebasePlugin = this.getWindow().FirebasePlugin;
        if (!firebasePlugin) {
            console.warn('FirebasePlugin no initialized into window');
            return;
        }
        // Устанавливаем кастомные ключи ошибки
        if (data.data) {
            for (const key in data.data) {
                if (data.data.hasOwnProperty(key)) {
                    firebasePlugin.setCrashlyticsCustomKey(key, data.data[key]);
                }
            }
        }
        // Устанавливаем тип ошибки в кастомный ключ
        firebasePlugin.setCrashlyticsCustomKey('type', data.type);

        // Получаем стек ошибки, если можем
        StackTrace.fromError(data.error)
            .then((stack) => {
                firebasePlugin.logError(data.title, stack, () => {
                    console.log('%cSend error to firebase 🔥\n' + '%c' + data.error.stack, 'color:#ff6a00', 'color: #838c95');
                });
            })
            .catch((err) => {
                firebasePlugin.logError(data.title, null, () => {
                    console.log('%cSend error to firebase 🔥\n' + '%c' + data.error.message, 'color:#ff6a00', 'color: #838c95');
                });
            });
    }

    addUserToPush(userId = null) {
        if (userId) {
            this.firebasex.subscribe(userId);
            this.firebasex.setUserId(userId);
        }
    }

    getUserToken() {
        // return '04c26b69b2de27fcb1df8ef685b9db9b';
        // return '14da6dc5f28a0ed52c75752772e1feec';
        // return '998002452a4317528a94a00dbc23f64d';
        return localStorage.getItem('com.ionicframework.hatimaki__token');
    }

    getGuestToken() {
        return localStorage.getItem('com.ionicframework.hatimaki__guest');
    }

    setGuestToken(guest) {
        localStorage.setItem('com.ionicframework.hatimaki__guest', guest);
    }

    removeGuestToken() {
        localStorage.removeItem('com.ionicframework.hatimaki__guest');
    }

    subscribePush(callback: () => void, callbackPush: (data: any) => void = null): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.isIos()) {
                this.firebasex.subscribe('ios');
            }

            if (this.isAndroid()) {
                this.firebasex.subscribe('android');
            }

            this.firebasex.subscribe('all');

            // Проверяем разрешения на уведомления
            this.firebasex.hasPermission().then((status) => {
                if (!status) {
                    // Запрашиваем разрешение на уведомления, если оно не было предоставлено
                    return this.firebasex.grantPermission().then(() => {
                        console.log('Notification permission granted');
                    }).catch((error) => {
                        console.error('Error granting notification permission', error);
                        reject(error); // Прерываем, если возникла ошибка
                    });
                }
            }).then(() => {
                // Получаем токен
                return this.firebasex.getToken();
            }).then((token) => {
                this.deviceId = token;

                // Вызываем колбэк, когда токен получен
                if (callback) {
                    callback();
                }

                // Успешно завершили подписку
                resolve();
            }).catch((error) => {
                console.error('Error during subscribe process', error);
                reject(error);
            });

            // Подписываемся на получение уведомлений
            this.firebasex.onMessageReceived().subscribe(data => {
                // Обрабатываем данные из пуш-уведомлений
                if (callbackPush) {
                    callbackPush(data);
                }
            });
        });
    }

    public unSubscribePush(userId = null) {
        this.firebasex.clearAllNotifications();

        if (this.isIos()) {
            this.firebasex.unsubscribe('ios');
        }

        if (this.isAndroid()) {
            this.firebasex.unsubscribe('android');
        }

        if (userId) {
            this.firebasex.unsubscribe(userId);
        }
    }

    public gaPageView() {

        if (this.isDevice()) {
            // const p = this.router.url.replace('/', '');
            //
            // this.firebase.setScreenName(p);
            // this.sendEvent('page_view', {page: p});
        }

    }

    getVersion(callback: () => void) {

        if (this.isCordova()) {

            this.appVersion.getVersionNumber().then((ver) => {
                this.appVersionNumber = ver;
                callback();
            });

        } else {
            this.appVersionNumber = 'browser';
            callback();
        }

    }

    initAppMetrika() {
        if (this.isCordova() && typeof this.getWindow().appMetrica !== 'undefined') {
            AnalyticsService.initAppMetrica(this.appVersionNumber);
        }
    }

    closeModal(data: any = {action: 'close'}) {
        if (this.modalWindow.length) {
            this.modalController.dismiss(data);
        }
    }

    deleteProfile(data: any = {action: 'deleteProfile'}) {
        if (this.modalWindow) {
            this.modalController.dismiss(data);
        }
    }

    async openModal(component, props = null, callback: (data: any) => void = null, swipeToClose: boolean = true) {
        const {selector} = this.factory.resolveComponentFactory(component);
        // todo initialBreakpoint работают только в 6 версии @ionic/angular
        this.modalWindow.push(await this.modalController.create({
            component,
            componentProps: props,
            swipeToClose,
            // initialBreakpoint: 0.6,
            // breakpoints: [0, 0.6],
            enterAnimation: popupEnterAnimation,
            leaveAnimation: popupLeaveAnimation,
            cssClass: 'app-modal modal-' + selector + 'bottom-modal-component',
        }));

        this.modalWindow[this.modalWindow.length - 1].onDidDismiss().then((res) => {

            if (res.data && callback) {
                callback(res.data);
            }

            if (this.modalWindow.length <= 1) {
                // сбрасывать этот флаг только если закрываемый модал - последний в стеке.
                this.isOpenModal = false;
            }

            // (на всякий случай) перед pop() обнулить элемент
            this.modalWindow[this.modalWindow.length - 1] = null;
            this.modalWindow.pop();
        });

        await this.modalWindow[this.modalWindow.length - 1].present().then(() => {
            this.isOpenModal = true;
        });

        return this.modalWindow;
    }

    public async showMessage(title, message = null, callback: () => void = null) {
        if (this.alertWindow) {
            await this.alertController.dismiss();
        }
        this.alertWindow = await this.alertController.create({
            mode: this.isIos() ? 'ios' : 'md',
            header: title,
            message,
            buttons: ['Ok']
        });

        await this.alertWindow.present();

        await this.alertWindow.onDidDismiss().then((res) => {

            if (callback) {
                callback();
            }

            this.alertWindow = null;
        });
    }

    async showLoading() {
        this.loadingCounter++;

        if (!this.isLoading) {

            this.isLoading = true;

            this.loading = await this.loaderController.create({
                message: !this.menuReady ? '' : '<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>',
                spinner: null,
                cssClass: 'spinner',
                showBackdrop: false
            });
            await this.loading.present();
        }
    }

    async hideLoading(all = false) {
        this.loadingCounter--;

        if (all) {
            this.loadingCounter = 0;
        }

        if (this.loadingCounter <= 0) {

            if (this.loading) {
                this.loading.dismiss();
                this.loading = null;
            }

            this.loadingCounter = 0;

            // if (this.isLoading) {
            this.isLoading = false;
            return await this.loaderController.dismiss(null, 'cancel');
            // }


        }

        return null;

    }


    async openPicker(
        columnOptions: PickerColumnOption[],
        selectedIndex: number = 0,
        classPicker: string,
        callback: (selectedSideDish: ProductOffer & { selectedIndex: number }) => void
    ) {
        const options: PickerOptions = {
            buttons: [
                {
                    text: 'Отмена',
                    role: 'cancel'
                },
                {
                    text: 'Готово'
                }
            ],
            columns: [{
                name: 'sideDish',
                options: columnOptions
            }],
            mode: 'ios',
            cssClass: classPicker
        };

        const picker = await this.pickerCtrl.create(options);
        picker.columns[0].selectedIndex = selectedIndex;
        picker.present();

        picker.onDidDismiss().then(async response => {
            if (response.data) {
                const selectedSideDish: (ProductOffer & { selectedIndex: number }) = response.data.sideDish.value;
                const col = await picker.getColumn('sideDish');
                const selectedIndexTemp = col.selectedIndex;

                selectedSideDish.selectedIndex = selectedIndexTemp;
                callback(selectedSideDish);
            }
        });
    }

    getSassVar(varName) {
        const appStyles = window.getComputedStyle(document.getElementsByTagName('ion-app')[0]);
        return parseInt(appStyles.getPropertyValue(varName), 0);
    }

    getIpAddress(callback: (ipAddress) => void) {
        this.networkInterface.getWiFiIPAddress()
            .then(address => {
                callback(address.ip);
            })
            .catch(error => {
                this.networkInterface.getCarrierIPAddress()
                    .then(address => {
                        callback(address.ip);
                    })
                    .catch(err => {
                        callback('127.0.0.1');
                    });
            });
    }

    shareApp() {
        let link;
        if (this.isIos()) {
            link = 'https://apps.apple.com/ru/app/hatimaki/id1325997612';
        } else {
            link = 'https://play.google.com/store/apps/details?id=com.ionicframework.hatimaki&hl=ru';
        }
        const options = {
            message: link
        };
        this.socialSharing.shareWithOptions(options);
    }

    resetMenuMainSwiper() {
        this.eventResetMenuSwiper.next();
    }

    public async selectPhoto(callback: (imageData: string) => void) {
        const actionSheet = await this.actionSheetController.create({
            mode: this.isAndroid() ? 'md' : 'ios',
            buttons: [
                {
                    text: 'Выбрать фото',
                    handler: () => {
                        this.makePhoto(this.camera.PictureSourceType.PHOTOLIBRARY, callback);
                    }
                },
                {
                    text: 'Сделать фото',
                    handler: () => {
                        this.makePhoto(this.camera.PictureSourceType.CAMERA, callback);
                    }
                },
                {
                    text: 'Отмена',
                    role: 'cancel',
                    handler: () => {
                    }
                }
            ]
        });
        await actionSheet.present();
    }

    public makePhoto(type, callback: (imageData: string) => void) {
        if (this.isCordova()) {


            const options: CameraOptions = {
                quality: 100,
                destinationType: this.camera.DestinationType.DATA_URL,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                correctOrientation: true,
                cameraDirection: this.camera.Direction.FRONT,
                sourceType: type
            };

            // console.log(options);

            this.camera.getPicture(options).then((imageData) => {
                this.updateStatusBar();
                callback(imageData);

            }, (err) => {
                this.updateStatusBar();
                // Handle error
            });
        }
    }

    // фоновое обновление каталога для загрузки актуального меню ланчей
    startUpdateCatalogTimer() {
        const deviceNow = new Date().getTime(); // Получаем текущее время устройства

        const serverNow = deviceNow; // Используем текущее время устройства вместо serverTime

        const timeStart =
            (this.startLaunch?.getTime() - serverNow + (24 * 60 * 60 * 1000)) % (24 * 60 * 60 * 1000) + (deviceNow - serverNow);
        const timeEnd =
            (this.endLaunch?.getTime() - serverNow + (24 * 60 * 60 * 1000)) % (24 * 60 * 60 * 1000) + (deviceNow - serverNow);

        // console.log(
        //     'обновим каталог через: ' +
        //     (Math.floor(timeStart / (1000 * 60 * 60))) + ' часов, ' +
        //     ((timeStart / (1000 * 60)) % 60) + ' минут'
        // );
        // console.log(
        //     'обновим каталог через: ' +
        //     (Math.floor(timeEnd / (1000 * 60 * 60))) + ' часов, ' +
        //     ((timeEnd / (1000 * 60)) % 60) + ' минут'
        // );

        // Рассчитываем время до старта и окончания обновления каталога

        if (this.updateCatalog.startTimer) {
            clearTimeout(this.updateCatalog.startTimer);
            this.updateCatalog.startTimer = null;
        }
        if (this.updateCatalog.stopTimer) {
            clearTimeout(this.updateCatalog.stopTimer);
            this.updateCatalog.stopTimer = null;
        }

        // Запускаем таймеры для обновления каталога
        this.updateCatalog.startTimer = setTimeout(() => {
            this.updateCatalog.emitter.emit(); // Инициируем событие обновления каталога
        }, timeStart);
        this.updateCatalog.stopTimer = setTimeout(() => {
            this.updateCatalog.emitter.emit(); // Инициируем событие обновления каталога
        }, timeEnd);
    }
}
