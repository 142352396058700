import {Component, Input, OnInit} from '@angular/core';
import {Address} from '../../../entity/address.entity';
import {AddressService} from '../../../models/address.service';
import {UserService} from '../../../models/user.service';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-save-address',
    templateUrl: './popup-save-address.component.html',
    styleUrls: ['./popup-save-address.component.scss'],
})
export class PopupSaveAddressComponent implements OnInit {
    @Input() address: Address;
    private addressService: AddressService;
    private userService: UserService;
    errorMessage = '';
    private appService: AppService;


    constructor(
        addressService: AddressService,
        userService: UserService,
        appService: AppService,
    ) {
        this.addressService = addressService;
        this.userService = userService;
        this.appService = appService;
    }

    ngOnInit() {
        this.address.id = 0;
        this.address.name = '';
    }

    close() {
        this.appService.closeModal();
    }

    saveTemplate() {
        if (this.address.name === '') {
            this.errorMessage = 'Поле обязательно для заполнения';
            return;
        }

        this.addressService.updateAddress(this.address, (data) => {
            this.appService.closeModal({action: 'saved', status: data});
        }, true);
    }

    onCancel() {
        this.appService.closeModal({action: 'canceled'});
    }
}
