import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
    selector: 'app-submit-item',
    templateUrl: 'item.html',
    styleUrls: ['item.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class SubmitItemComponent implements OnDestroy, OnInit {

    @Output() buttonClick = new EventEmitter<void>();

    @Input() title: string;
    @Input() disabled = true;

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    onClick() {
        this.buttonClick.emit();
    }

}
