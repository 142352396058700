import {Component, Input, OnInit} from '@angular/core';
import {CommentService} from '../../../models/comment.service';
import {Comment} from '../../../entity/comment.entity';
import {UserService} from '../../../models/user.service';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-save-comment',
    templateUrl: './popup-save-comment.component.html',
    styleUrls: ['./popup-save-comment.component.scss'],
})
export class PopupSaveCommentComponent implements OnInit {
    @Input() text: string;

    private commentService: CommentService;
    private userService: UserService;
    private comment: Comment;
    private appService: AppService;

    constructor(
        commentService: CommentService,
        userService: UserService,
        appService: AppService,
    ) {
        this.commentService = commentService;
        this.userService = userService;
        this.appService = appService;
    }

    ngOnInit() {
        this.comment = new Comment();
        this.comment.name = 'template';
        if (this.text) {
            this.comment.text = this.text;
        }
    }

    close() {
        this.appService.closeModal();
    }

    saveComment() {
        this.commentService.updateComments(this.comment, (data) => {
            if (data) {
                this.appService.closeModal({action: 'saved'});
            } else {
                this.appService.closeModal({action: 'error save'});
            }
        }, true);
    }

    onCancel() {
        this.appService.closeModal({action: 'canceled'});
    }
}
