import {Injectable} from '@angular/core';

function _window(): any {
    return window;
}

@Injectable()
export class AnalyticsService {

    private static appmetrica = false;

    constructor() {

    }

    static initAppMetrica(version) {
        const configuration = {
            apiKey: '600edab4-a593-48ca-8985-f93b8ff514a7',
            handleFirstActivationAsUpdate: false,
            appVersion: version,
            logs: true,
            sessionTimeout: 15
        };

        _window().appMetrica.activate(configuration);

        this.appmetrica = true;
    }

    static pageVisit(mainPage, subPage = null, sub2page = null) {
        let data = {
            [mainPage]: {}
        };

        if (subPage) {
            data = {
                [mainPage]: subPage
            };
        }

        if (sub2page) {
            data = {
                [mainPage]: {
                    [subPage]: sub2page
                }
            };
        }

        this.sendEvent('Просмотр Страниц', data);
    }

    static reg(userId) {
        this.sendEvent('Sign In', {
            UserId: userId
        });
    }

    static login(userId) {
        this.sendEvent('Log In', {
            UserId: userId
        });
    }

    static addToCart(product, page) {
        this.sendEvent('Корзина', {
            Product: product,
            Page: page,
        });
    }

    static cartPage(userId, total, products) {
        this.sendEvent('Cart', {
            Products: products,
            Cost: total,
            Currency: 'RUB',
            UserId: userId
        });
    }

    static finishOrder(orderId, cost) {
        this.sendEvent('Заказ', {
            Cost: cost,
            Currency: 'RUB',
            OrderId: orderId,
            // 'IikoId': iikoId,
            // 'Status': orderId === iikoId ? 'KO' : 'OK'
        });
    }

    static sendEvent(event, data) {

        if (this.appmetrica) {
            _window().appMetrica.reportEvent(event, data);
        }
    }

}
