import {Entity} from '../core/main.entity';
import {WokItems} from './wokItems.entity';
import {WokItem} from './wokItem.entity';

export class Wok implements Entity {
    steps: WokItems[];
    without: WokItem[];
    options: WokOptions;
}

export class WokOptions implements Entity {
    img: string;
    title: string;
}
