import {Injectable} from '@angular/core';
import {IonRouterOutlet, NavController} from '@ionic/angular';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {AppService} from './app.service';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {

    nav: NavController;
    router: Router;
    appService: AppService;
    storedPath: string;

    public routerOutlet: IonRouterOutlet;

    public previousUrl;
    public currentUrl;

    // public stack: StackController;

    constructor(
        nav: NavController,
        // stack: StackController,
        router: Router,
        routes: RouterModule,
        appService: AppService,
    ) {
        this.nav = nav;
        // this.stack = stack;
        this.router = router;
        this.appService = appService;

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
        });
    }

    public goToPage(page: any, root: boolean = false, data: any = null) {

        const extras = {
            replaceUrl: false,
            queryParams: null,
        };

        if (root) {
            extras.replaceUrl = true;
        }

        if (data) {
            extras.queryParams = data;
        }

        this.appService.closeMenu();

        // if (root) {
        //     this.nav.navigateRoot('/' + page, extras);
        // } else {
        this.nav.navigateForward('/' + page, extras);
        // }


    }

    restorePath(alternativePage: string, root: boolean = false, data: any = null) {
        const token = localStorage.getItem('com.ionicframework.hatimaki__token');

        if (this.storedPath && token) {

            this.goToPage(this.storedPath, root, data);

            this.storedPath = null;
        } else {
            this.goToPage(alternativePage, root, data);
        }
    }

    public back(fullBack = false) {

        if (this.appService.back() && this.canGoBack()) {

            if (fullBack === false && this.canGoBack()) {
                this.routerOutlet.pop(1);
            } else if (fullBack) {

                for (let i = 1; i <= 10; i++) {
                    if (this.canGoBack(i) === false) {
                        this.routerOutlet.pop(i - 1);
                    }
                }

            }

        }
    }

    canGoBack(index = 1) {
        return this.routerOutlet?.canGoBack(index);
    }


    routeActive(path: string) {
        return this.router.isActive(path, false);
    }
}
