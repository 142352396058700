import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef, AfterViewInit,
} from '@angular/core';
import {AppService} from '../../services/app.service';
import {MainItem} from '../../core/main.item';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {ProductModalService} from 'src/app/services/product-modal.service';
import {CartService} from 'src/app/models/cart.service';
import {Product} from '../../entity/product.entity';
import {ProductOffer} from '../../entity/productOffer.entity';
import {CatalogService} from 'src/app/models/catalog.service';
import {PickerColumnOption} from '@ionic/core';
import {RecommendProductService} from 'src/app/models/recommend-product.service';
import {UserService} from 'src/app/models/user.service';
import {AnalyticsService} from '../../services/analytics.service';
import Scrollbar from 'smooth-scrollbar';
import CustomScrollBarPlugin from '../../core/CustomScrollBarPlugin';
import {ModalService} from '../../services/modal.service';
import {Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'modal-item',
    styleUrls: ['./modal-item.scss'],
    templateUrl: 'modal-item.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ModalItemComponent extends MainItem implements OnDestroy, OnInit, AfterViewInit {

    @Input() dish: Product;
    recommend: { title: string, products: Product[] };

    @ViewChild('shadow', {static: false})
    shadowRef: ElementRef;

    @ViewChild('dishInfo', {static: false})
    dishInfoRef: ElementRef;

    @ViewChild('selectSideDish', {static: false})
    selectSideDishRef: ElementRef;

    @ViewChild(SwiperComponent, {static: false})
    swiperComponent?: SwiperComponent;

    @ViewChild('scrollContainer', {static: false})
    scrollContainer;

    subscriptions: Subscription[] = [];

    quantity = 0;
    product: Product = new Product();
    offerId: number;
    offers: ProductOffer[];
    selectedOfferIdx: number;
    selectedOfferIndex: number;
    sideDishes: ProductOffer[] = [];
    isLoadedRecommendedProducts = false;
    weight: string | null = null;
    dishPrice = 0;
    totalPrice = 0;

    appService: AppService;
    productModalService: ProductModalService;
    modalService: ModalService;
    cartService: CartService;
    catalogService: CatalogService;
    cdr: ChangeDetectorRef;
    recommendProductService: RecommendProductService;
    userService: UserService;
    sanitization: DomSanitizer;

    opacity = 0;
    infoOpacity = 0;
    modalItemTop = 0;
    isIosParallax: boolean;
    productPrice = 0;
    isEnabledScroll = false;
    selectedWeightTab = 0;  // 0 - вкладка "100гр.", 1 - вкладка "Всего"
    favButtonLocked = false;

    // для iOS - значение селекта по умолчанию
    sideDishName: string;
    selectedSideDish: ProductOffer & { selectedIndex?: number };
    nutritional: {
        proteins: string,
        fats: string,
        carbo: string,
        kkal: string
    }[] = [null, null];
    private scrollBar: Scrollbar = null;

    constructor(
        appService: AppService,
        productModalService: ProductModalService,
        cartService: CartService,
        catalogService: CatalogService,
        cdr: ChangeDetectorRef,
        recommendProductService: RecommendProductService,
        userService: UserService,
        modalService: ModalService,
        sanitization: DomSanitizer
    ) {
        super();

        this.appService = appService;
        this.productModalService = productModalService;
        this.cartService = cartService;
        this.catalogService = catalogService;
        this.cdr = cdr;
        this.recommendProductService = recommendProductService;
        this.userService = userService;
        this.modalService = modalService;
        this.sanitization = sanitization;


        this.onScroll = this.onScroll.bind(this);
    }

    public recommendConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 'auto',
        observer: true,
        nested: false,
        touchMoveStopPropagation: true,
        keyboard: false,
        mousewheel: true,
        scrollbar: false,
        navigation: false,
        pagination: false
    };

    ngOnInit() {
        this.copyDish(this.dish);

        AnalyticsService.pageVisit('Товар', this.dish.name);

        if (this.product.offers && this.product.offers.length) {
            // this.selectedOfferIdx = this.product.offers.length - 1;
            // this.product.offerId = this.product.offers[this.selectedOfferIdx].id;
            const activeOffer = this.product.offers.find(offer => offer.id === this.product.offerId);
            this.selectedOfferIdx = this.product.offers.indexOf(activeOffer);
            this.setNutritionalValue(
                this.product.offers[this.selectedOfferIdx].nutritionalValue,
                this.product.offers[this.selectedOfferIdx].nutritionalValue100);
            this.getSelectedOfferIndex(this.product.offerId);
        } else {
            this.setNutritionalValue(this.product.nutritionalValue, this.product.nutritionalValue100);
        }
        this.getRecommended();
        this.getSideDishes();
        this.getWeight();
        this.getParallax();
        this.qtyInCart();

        setTimeout(() => {
            this.createVirtualScroll(this.scrollContainer.nativeElement);
        });

        this.subscriptions.push(this.catalogService.updateStopList$.subscribe(() => {
            this.product.isStopList = this.dish.isStopList;
            if (this.product.offers?.length > 0) {
                this.product.offers[this.selectedOfferIndex].isStopList = this.dish.offers[this.selectedOfferIndex]?.isStopList;
            }
            this.cdr.detectChanges();
        }));
    }

    createVirtualScroll(targetElement) {
        Scrollbar.use(CustomScrollBarPlugin);
        this.scrollBar = Scrollbar.init(
            targetElement,
            {
                damping: 0.1,
                plugins: {
                    myPlugin: {
                        parallaxSelector: '.product-page-info-wrapper .lazy-load-img',
                        isModalGesture: () => this.modalService.isGesture
                    }
                }
            });
        this.scrollBar.addListener(this.onScroll);
        this.onScroll({offset: {x: 0, y: 0}});
    }

    ionViewWillEnter() {
    }

    ngAfterViewInit() {
    }

    qtyInCart() {

        if (this.product.offerId) {
            this.quantity = this.cartService.getQty(this.product.id, this.product.offerId);
        } else {
            this.quantity = this.cartService.getQty(this.product.id);
        }
        this.cdr.detectChanges();
    }

    getSideDishes() {
        if (this.product.offerSelectType === 'select') {
            this.sideDishes = this.product.offers;
            this.setActiveSideDish();
        }
    }

    // getPrice() {
    //     return this.product.getPrice(1);
    // }

    getPrice(typePrice: string) {
        return this.product.getPrice(1, typePrice);
    }

    onScroll(e: any) {
        this.modalItemTop = e.offset.y;
        this.setStyleCardImg();
        this.modalService.scrollModal(this.modalItemTop);
    }

    ngOnDestroy() {
        if (this.scrollBar){
            this.scrollBar.removeListener(this.onScroll);
            this.scrollBar.destroy();
            this.scrollBar = null;
        }
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.length = 0;
    }


    getWeight() {
        if (!this.product.offers || this.product.offers.length === 0) {
            this.weight = this.dish.weight;

        } else if (this.product.offers.length === 1) {
            if (this.product.categoryId.toString() === '11') {
                this.weight = this.product.offers[0].litrag;
            } else {
                this.weight = this.product.offers[0].gramm;
            }
        }
    }

    copyDish(dish: Product) {
        Object.assign(this.product, dish);
    }

    getOfferName(offer) {
        if (offer.size) {
            return offer.size;
        } else if (offer.litrag) {
            return offer.litrag;
        } else if (offer.gramm) {
            return offer.gramm;
        }
        return '';
    }

    getSelectedOfferIndex(offerId: number) {
        for (let i = 0; i <= this.product.offers.length - 1; i++) {
            if (this.product.offers[i].id === offerId) {
                this.selectedOfferIndex = i;
            }
        }
    }

    selectOffer(offerId: number) {
        this.product.offerId = offerId;
        this.dish.offerId = offerId;
        this.productModalService.setActiveOffer(this.product, offerId);
        this.getNutritional(offerId);
        this.qtyInCart();
        this.getSelectedOfferIndex(offerId);
    }

    getNutritional(offerId: number) {
        this.product.offers.forEach(offer => {
            if (offer.id === offerId) {
                this.setNutritionalValue(offer.nutritionalValue, offer.nutritionalValue100);
            }
        });
    }

    setStyleCardImg() {
        this.shadowRef.nativeElement.style.setProperty('opacity', `${this.modalItemTop / 100}`);
        this.dishInfoRef.nativeElement.style.setProperty('opacity', `${1 - this.modalItemTop / 100}`);
    }

    getParallax() {
        const modalContent = document.querySelector('.modal-content') as HTMLElement;

        this.isIosParallax = this.supportCSSStyle('-webkit-overflow-scrolling', modalContent) ? true : false;
    }

    supportCSSStyle(prop, modalContent) {
        let isSupport = false;

        if (prop in modalContent.style) {
            isSupport = true;
        }

        return isSupport;
    }

    addQty(event: Event) {
        if (this.isNoBuyTime){
            return;
        }
        event.stopPropagation();
        this.quantity = this.cartService.addToCart(this.product, +1, this.product.offerId);
        this.productModalService.updateItemQuantityInMenu();
    }

    removeQty(event: Event) {
        if (this.isNoBuyTime){
            return;
        }
        event.stopPropagation();
        this.quantity = this.cartService.addToCart(this.product, -1, this.product.offerId);
        this.productModalService.updateItemQuantityInMenu();
    }

    public openSideDishPicker(evt: Event) {
        evt.stopPropagation();
        const options = this.getColumnOptions();
        const selectedIndex = this.getActivePickerIndex();
        const classPicker = 'side-dish-picker';

        this.appService.openPicker(options, selectedIndex, classPicker, (selectedSideDish: ProductOffer & { selectedIndex: number }) => {
            this.selectedSideDish = selectedSideDish;
            this.product.offerId = selectedSideDish.id;
            this.saveSideDishToCatalog(selectedSideDish);
            this.productModalService.updateItemQuantityInMenu();
        });
    }

    getActivePickerIndex() {
        let index = 0;
        if (this.catalogService.selectedSideDishes[this.product.id]) {
            index = this.catalogService.selectedSideDishes[this.product.id].selectedIndex;
        }
        return index;
    }

    private getColumnOptions() {
        const options: PickerColumnOption[] = [];
        this.product.offers.forEach(offer => {
            options.push({
                text: offer.subName,
                value: offer
            });
        });

        return options;
    }

    setActiveSideDish() {
        if (this.sideDishes.length) {

            if (this.catalogService.selectedSideDishes[this.product.id]) {
                this.selectedSideDish = this.catalogService.selectedSideDishes[this.product.id];
                this.sideDishName = this.selectedSideDish.subName;
                this.selectOffer(this.selectedSideDish.id);
                this.setNutritionalValue(this.selectedSideDish.nutritionalValue, this.selectedSideDish.nutritionalValue100);
            } else {
                this.saveSideDishToCatalog();
            }
        }
    }

    saveSideDishToCatalog(selectedItem: ProductOffer & { selectedIndex?: number } = null) {
        let offerId: number;
        if (selectedItem && selectedItem.subName) {
            offerId = selectedItem.id;
            this.sideDishName = selectedItem.subName;
            this.catalogService.selectedSideDishes[this.product.id] = selectedItem;
            this.selectedSideDish = selectedItem;
        } else {
            offerId = this.product.offers[0].id;
            this.selectedSideDish = this.product.offers[0];
            this.sideDishName = this.product.offers[0].subName;
        }

        this.selectOffer(offerId);
        this.setNutritionalValue(this.selectedSideDish.nutritionalValue, this.selectedSideDish.nutritionalValue100);
    }

    onChangeSelect(evt: Event) {
        evt.stopPropagation();
        this.catalogService.selectedSideDishes[this.product.id] = this.selectedSideDish;
        this.product.offerId = this.selectedSideDish.id;
        this.selectOffer(this.product.offerId);
        this.setNutritionalValue(this.selectedSideDish.nutritionalValue, this.selectedSideDish.nutritionalValue100);
        this.productModalService.updateItemQuantityInMenu();
    }

    setNutritionalValue(nutritionalObj, nutritionalObj100) {

        this.nutritional[1] = {
            proteins: nutritionalObj.proteins,
            fats: nutritionalObj.fats,
            carbo: nutritionalObj.carbo,
            kkal: nutritionalObj.kkal
        };

        if (nutritionalObj100) {
            this.nutritional[0] = {
                proteins: nutritionalObj100.proteins,
                fats: nutritionalObj100.fats,
                carbo: nutritionalObj100.carbo,
                kkal: nutritionalObj100.kkal
            };
        } else {
            this.nutritional[0] = null;
            this.changeWeightTab(1);
        }

        this.cdr.detectChanges();
    }

    getRecommended() {
        this.recommendProductService.getRecommended(this.dish.id, (data) => {
            this.recommend = data;
            this.cdr.detectChanges();
        });
    }

    get isNoBuyTime() {
        return !this.product.availability && this.product.availabilityText;
    }

    changeWeightTab(i: number) {
        if (this.nutritional[i]) {
            this.selectedWeightTab = i;
        }
    }

    onFavButtonClick(event: Event, product) {
        if (!this.favButtonLocked) {
            this.favButtonLocked = true;

            this.catalogService.changeFavoriteState(product, (res) => {
                if (res.status) {
                    this.product.isFavorite = res.result;
                }
                this.favButtonLocked = false;
                this.cdr.detectChanges();

                this.catalogService.deleteHiddenItems(() => {
                    // this.cdr.detectChanges();
                });
            });
        }
    }
}
