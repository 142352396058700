import {Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-work-time',
    templateUrl: './popup-work-time.component.html',
    styleUrls: ['./popup-work-time.component.scss'],
})
export class PopupWorkTimeComponent implements OnInit {
    private appService: AppService;
    @Input() value;

    constructor(
        appService: AppService
    ) {
        this.appService = appService;
    }

    ngOnInit() {
    }

    close() {
        this.appService.closeModal();
    }
}
