import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';


import {LazyLoadImageModule} from 'ng-lazyload-image';

import {SwiperModule} from 'ngx-swiper-wrapper';
import {ProductModalComponent} from './product-modal';
import {ModalItemComponent} from '../modal-item/modal-item.component';
import {MainPageModule} from 'src/app/core/page.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: '',
                component: ProductModalComponent
            }
        ]),
        LazyLoadImageModule,
        MainPageModule,
        SwiperModule
    ],
    exports: [
        ProductModalComponent
    ],
    declarations: [
        ProductModalComponent,
        ModalItemComponent
    ]
})
export class ProductModalModule {

}
