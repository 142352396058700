import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HelperService {

    constructor() {
    }

    /*
    * @TODO вынести в АПИ
    * */
    links = {
        confidence: 'https://www.hatimaki.ru/politika_obrabotki_pd/'
    };

    static chunk(array, size) {
        const chunkedArr = [];
        const copied = [...array];
        const numOfChild = Math.ceil(copied.length / size);
        for (let i = 0; i < numOfChild; i++) {
            chunkedArr.push(copied.splice(0, size));
        }
        return chunkedArr;
    }

    static randomColor() {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    static getLazyDefaultImage(id) {

        const hitMenuImage = 'assets/img/svg/hit.svg';
        const childMenuImage = 'assets/img/svg/child_menu.svg';
        const pizzaImage = 'assets/img/svg/pizza.svg';
        const healthFoodImage = 'assets/img/svg/health_food.svg';
        const snackImage = 'assets/img/svg/snack.svg';
        const wokImage = 'assets/img/svg/wok.svg';
        const sushiImage = 'assets/img/svg/sushi.svg';
        const setsImage = 'assets/img/svg/set.svg';
        const soupImage = 'assets/img/svg/soup.svg';
        const hotMealImage = 'assets/img/svg/hot_meal.svg';
        const saladImage = 'assets/img/svg/salad.svg';
        const smoothieImage = 'assets/img/svg/smoothie.svg';
        const desertImage = 'assets/img/svg/desert.svg';
        const drinkImage = 'assets/img/svg/drink.svg';
        const sauceImage = 'assets/img/svg/sauce.svg';
        const veganImage = 'assets/img/svg/vegan.svg';

        switch (id.toString()) {
            case '0':
                return hitMenuImage;
            case '65':
                return healthFoodImage;
            case '39':
                return childMenuImage;
            case '1':
                return wokImage;
            case '9':
                return pizzaImage;
            case '67':
            case '68':
            case '21':
            case '56':
            case '23':
            case '22':
            case '24':
            case '40':
            case '41':
            case '10':
            case '42':
            case '43':
            case '44':
            case '13':
                return sushiImage;
            case '19':
                return setsImage;
            case '38':
                return soupImage;
            case '47' :
            case '54':
            case '45':
            case '70':
            case '71':
            case '72':
                return hotMealImage;
            case '51':
                return saladImage;
            case '52':
                return snackImage;
            case '37':
                return veganImage;
            case '55':
                return smoothieImage;
            case '12':
                return desertImage;
            case '59':
            case '60':
            case '11':
                return drinkImage;
            case '14':
                return sauceImage;
        }
    }

    static toFixed(num, fixed) {
        fixed = fixed || 0;
        fixed = Math.pow(10, fixed);
        return Math.floor(num * fixed) / fixed;
    }

    static norm(val, max, min) {
        return (val - min) / (max - min);
    }

    static scaleValue(value, from, to) {
        const scale = (to[1] - to[0]) / (from[1] - from[0]);
        const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
        return (capped * scale + to[0]);
    }

    static precisionRound(num, precision) {
        const factor = Math.pow(10, precision);
        return Math.round(num * factor) / factor;
    }

    static changeWithMagnitude(num, magnitude = 1) {
        return num / (1 - (num / magnitude));
    }

    static getRandomInt(min, max) {
        const minimum = Math.ceil(min);
        const maximum = Math.floor(max);

        return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    }


    static getRandomIntExcept(min, max, excludeArrayNumbers) {
        let randomNumber;

        if (!Array.isArray(excludeArrayNumbers)) {
            randomNumber = HelperService.getRandomInt(min, max);
            return randomNumber;
        }

        do {
            randomNumber = HelperService.getRandomInt(min, max);
        } while ((excludeArrayNumbers || []).includes(randomNumber));

        return randomNumber;
    }
}
