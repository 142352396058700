import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {AppService} from 'src/app/services/app.service';
import {CartService} from "../../../models/cart.service";

@Component({
    selector: 'app-popup-time-picker',
    templateUrl: './popup-time-picker.component.html',
    styleUrls: ['./popup-time-picker.component.scss'],
})
export class PopupTimePickerComponent implements OnInit, OnDestroy {

    private appService: AppService;
    @Input() value: string;
    @Input() timeStart: string;
    @Input() timeEnd: string;
    @Input() timeDelay: string;
    @Input() mode: 'delivery' | 'pickup';
    cartService: CartService;

    constructor(
        appService: AppService,
        cartService: CartService
    ) {
        this.appService = appService;
        this.cartService = cartService;
    }

    ngOnInit() {
    }

    close() {
        this.appService.closeModal();
    }

    ngOnDestroy() {
    }

    setTime() {
        this.appService.closeModal({action: 'setTime', mode: this.mode, value: this.value});
    }

    setTimeNull() {
        this.appService.closeModal({action: 'setTime', mode: this.mode, value: null});
    }

    get title() {
        return this.mode === 'pickup' ?
            this.cartService.cart.options.timePicker.pickupTitle :
            this.cartService.cart.options.timePicker.deliveryTitle;
    }

    get text() {
        return this.mode === 'pickup' ?
            this.cartService.cart.options.timePicker.pickupText :
            this.cartService.cart.options.timePicker.deliveryText;
    }
}
