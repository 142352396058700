import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MainItem} from '../../core/main.item';


@Component({
    selector: 'modal-overlay',
    templateUrl: 'modal-overlay.html',
    styleUrls: ['modal-overlay.scss']
})

export class ModalOverlayComponent extends MainItem implements OnDestroy, OnInit {
    @Input() modalClass: any;
    @Input() modalTitle: any;
    @Output() onClickButtonClose: EventEmitter<any> = new EventEmitter();
    @Output() onBackBtnClick: EventEmitter<any> = new EventEmitter();
    @Input() isPagination = false;
    @Input() isClose = true;
    @Input() showBtnBack = false;


    constructor() {
        super();
    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

    privateOnClickButtonClose() {
        this.onClickButtonClose.emit();
    }

    backBtnClick() {
        this.onBackBtnClick.emit();
    }
}
