import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    ChangeDetectorRef,
    Input, Injector
} from '@angular/core';
import {AppService} from '../../services/app.service';
import {CartService} from 'src/app/models/cart.service';
import {SwiperPaginationInterface, SwiperDirective, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {CatalogService} from 'src/app/models/catalog.service';
import {ProductModalService} from 'src/app/services/product-modal.service';
import {UserService} from '../../models/user.service';
import {Product} from '../../entity/product.entity';
import {NavigationService} from 'src/app/services/navigation.service';
import {RecommendProductService} from 'src/app/models/recommend-product.service';
import {MainModal} from '../../core/main.Modal';
import {ModalService} from '../../services/modal.service';
import {GestureController} from '@ionic/angular';
import {Subscription} from 'rxjs';

@Component({
    selector: 'product-modal',
    styleUrls: ['./product-modal.scss'],
    templateUrl: 'product-modal.html'
})
export class ProductModalComponent extends MainModal implements OnInit, AfterViewInit, OnDestroy {

    @Input() product: Product;

    @ViewChild('modalItem', {read: ElementRef, static: false})
    modalItemRef: ElementRef;

    @ViewChild(SwiperDirective, {static: false})
    directiveRef?: SwiperDirective;

    private closeSubscription: Subscription = null;

    appService: AppService;
    modalService: ModalService;
    gestureCtrl: GestureController;

    public userService: UserService;
    cartService: CartService;
    catalog: CatalogService;
    productModalService: ProductModalService;
    navigationService: NavigationService;
    recommendProductService: RecommendProductService;
    cdr: ChangeDetectorRef;

    modalTitle: string;
    modalDishes: Product[] = [];
    initialSlide: number;
    virtualData: any;
    modalSwiperConfig: SwiperConfigInterface = {
        spaceBetween: 15
    };

    private pagination: SwiperPaginationInterface = {
        el: '.modal-pagination',
        clickable: true,
        hideOnClick: false,
        dynamicBullets: true,
        dynamicMainBullets: 10
    };
    injector: Injector;

    constructor(
        injector: Injector,
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController,
        cartService: CartService,
        catalog: CatalogService,
        navigationService: NavigationService,
        recommendProductService: RecommendProductService,
        cdr: ChangeDetectorRef,
        productModalService: ProductModalService
    ) {
        super(appService, modalService, gestureCtrl);
        this.injector = injector;
        this.catalog = catalog;
        this.productModalService = productModalService;
        this.cartService = cartService;
        this.navigationService = navigationService;
        this.recommendProductService = recommendProductService;
        this.cdr = cdr;
    }

    ngOnInit() {
        this.initialSlide = this.productModalService.initialSlide;
        this.modalDishes = this.productModalService.modalDishes;
        this.modalTitle = this.productModalService.modalTitle;
        const activeDish = this.product;
        this.setActiveDish(activeDish);

        this.closeSubscription = this.appService.eventCloseProductModal.subscribe(() => {
            this.close();
        });

    }

    setActiveDish(activeDish: Product) {
        if (activeDish?.offers?.length) {
            this.productModalService.setActiveOffer(activeDish);
        }
    }

    ngAfterViewInit() {

        setTimeout(() => this.modalSwiperConfig = {
            slidesPerView: 1,
            threshold: 10,
            pagination: this.pagination,
            virtual: {
                slides: this.productModalService.modalDishes,
                renderExternal: data => {
                    this.virtualData = data;
                }
            }
        });
    }

    indexChange() {
        this.setActiveDish(this.modalDishes[this.directiveRef.getIndex()]);
        this.directiveRef.update();
    }


    openCart() {
        this.close();
        this.navigationService.goToPage('cart');
    }

    ngOnDestroy() {
        this.modalDishes.length = 0;

        if (this.closeSubscription) {
            this.closeSubscription.unsubscribe();
            this.closeSubscription = null;
            this.appService.productModalIsOpened = false;
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    getSelectedSideDish(dishId) {
        const activeDish = this.modalDishes[this.initialSlide];
        if (dishId === activeDish.id) {
            return this.productModalService.selectedSideDish;
        }
    }

    onClickButtonClose() {
        this.close();
    }
    swiperTouchStart() {
        this.lockGesture = true;
        this.modalService.isGesture = true;
    }

    swiperTransitionEnd() {
        this.lockGesture = false;
        this.modalService.isGesture = false;
    }
}
