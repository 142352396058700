import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppService} from '../../services/app.service';
import {environment} from '../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {CancelHttpService, CancelHttpType} from '../../services/cancel-http.service';

@Injectable()
export class MainInterceptor implements HttpInterceptor {

    constructor(
        private appService: AppService,
        private cancelHttpService: CancelHttpService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.includes(environment.baseUrl)) {
            const city = localStorage.getItem('com.ionicframework.hatimaki__city');

            if (city) {

                request = request.clone({
                    params: request.params.set('city', city),
                    setHeaders: {
                        City: city,
                    }
                });

            }

            const token = localStorage.getItem('com.ionicframework.hatimaki__token');

            let type = 'browser';

            if (this.appService.isCordova()) {
                type = this.appService.isIos() ? 'ios' : 'android';
            }

            if (token !== 'null' && token !== 'undefined' && token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + token,
                    }
                });
            }

            request = request.clone({
                setHeaders: {
                    Device: type + '-' + this.appService.appVersionNumber,
                }
            });

            request = request.clone({
                setHeaders: {
                    DeviceToken: this.appService.deviceId,
                }
            });
        }
        if (request.url.includes('cart.php')) {
            return next.handle(request).pipe(takeUntil(this.cancelHttpService.add(CancelHttpType.Cart)));
        }
        if (request.url.includes('catalog/filter')) {
            this.cancelHttpService.cancel(CancelHttpType.SearchFilter);
            return next.handle(request).pipe(takeUntil(this.cancelHttpService.add(CancelHttpType.SearchFilter)));
        }

        return next.handle(request);
    }
}
