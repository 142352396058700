import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Promotion } from 'src/app/entity/promotion.entity';
import {MainItem} from '../../core/main.item';

@Component({
    selector: 'blog-item',
    templateUrl: 'item.html'
})

export class BlogItemComponent extends MainItem implements OnDestroy, OnInit {

    @Input() blogItem: Promotion;
    sanitization: DomSanitizer;

    constructor(
        sanitization: DomSanitizer
    ) {
        super();
        this.sanitization = sanitization;
    }

    ngOnInit() {
    }

    ngOnDestroy() {}

    getBackgroundImageCss(imageUrl: string) {
        return this.sanitization.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }
}
