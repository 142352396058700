import {Injectable} from '@angular/core';
import {CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../../models/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
    private userService: UserService;

    constructor(
        userService: UserService,
        private router: Router,
    ) {
        this.userService = userService;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.userService.isLogin()) {
            return true;
        } else {
            this.userService.clearUserPhone();
            return this.router.parseUrl('auth/phone');
        }
    }

}
