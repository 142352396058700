import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {HelperService} from '../services/helper.service';
import {AppService} from '../services/app.service';
import {Product} from '../entity/product.entity';
import {Category} from '../entity/category.entity';
import {Promotion} from '../entity/promotion.entity';
import {Catalog, PizzaWOKStopList, Settings, Statuses} from '../entity/catalog.entity';
import {CategoryTypes} from '../entity/lists';
import {ApiResponse} from '../entity/apiResponse.entity';
import {HelpTexts} from '../entity/help-texts.entity';
import {Socials} from '../entity/socials.entity';
import {Subject} from 'rxjs';
import {PopupMessageComponent} from '../components/popups/popup-message/popup-message.component';
import {DeliveryType} from '../entity/cart.entity';
import {PizzaService} from './pizza.service';

@Injectable({
    providedIn: 'root'
})
export class CatalogService extends MainService {

    chunk: any;
    flipperCount = [];
    public filterData: { title: string, key: string }[] = [];

    mainProductArray: Product[] = [];
    products: Product[][] = [];
    categories: Category[] = [];
    favorites: Product[] = [];
    promos: Promotion[] = [];
    phone: string;
    selectedSideDishes = {};
    settings: Settings = null;
    socials: Socials = null;
    statuses: Statuses = null;
    vacancies = '';
    privacy_policy = '';
    privacy_policy_text = '';
    ymaps_apikey = '';
    pizzaWOKStopList: PizzaWOKStopList = null;

    eventUpdateStopList: Subject<any> = new Subject<any>();
    updateStopList$ = this.eventUpdateStopList.asObservable();
    updateFavorite$: EventEmitter<any> = new EventEmitter<any>();

    lastCheckedFavoritesIndex: number;

    public helpTexts: HelpTexts = {};
    private productsUpdated = new Subject();

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService,
        private pizzaService: PizzaService,
    ) {
        super(http, alert, loader, appService);
    }

    getProductsUpdated() {
        return this.productsUpdated.asObservable();
    }

    getCatalog(callback: (status: boolean) => void, token: string = null, loading = true) {
        this.appService.isMenuChanged = true;
        this.appService.showMenuObject.opacity = 0;
        const getData: any = {};
        if (token) {
            getData.token = token;
        }
        const city = this.appService.getCityId() || '0';
        let defaultAddress;
        try {
            defaultAddress = JSON.parse(localStorage.getItem('com.ionicframework.hatimaki__yandexLocalDefaultAddress_' + city));
        } catch (e) {
            defaultAddress = null;
        }
        if (defaultAddress) {
            if (defaultAddress?.type === DeliveryType.Courier) {
                getData.terminal_id = defaultAddress.courierPoint.terminal_bid;
                // передается delivery_type 1, для пересчёта цен, если пользователь не залогинен
                if (!token) {
                    getData.delivery_type = 1;
                }
            } else if (defaultAddress?.type === DeliveryType.Pickup) {
                getData.terminal_id = defaultAddress.pickup.id;
                // передается delivery_type 0, для пересчёта цен, если пользователь не залогинен
                if (!token) {
                    getData.delivery_type = 0;
                }
            }
        }
        this.getRequest(getData, (data: ApiResponse) => {
            // console.log('get catalog', data);
            // временно проставлено вручную, если не придёт smsAttemptsNumber
            if (!data.result.settings.authorization?.smsAttemptsNumber) {
                data.result.settings.authorization.smsAttemptsNumber = 3;
            }
            const hoursStart = data.result.settings.lunch?.hoursStart;
            const hoursEnd = data.result.settings.lunch?.hoursEnd;

            this.pizzaService.data = data.result.pizzas?.data;
            this.pizzaService.options = data.result.pizzas?.options;
            this.privacy_policy_text = data.result.privacy_policy_text;

            const timeFormatRegex = /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/;

            if (timeFormatRegex.test(hoursStart) && timeFormatRegex.test(hoursEnd)) {
                this.appService.startLaunch.setHours(Number(hoursStart.split(':')[0]), Number(hoursStart.split(':')[1]));
                this.appService.endLaunch.setHours(Number(hoursEnd.split(':')[0]), Number(hoursEnd.split(':')[1]));
                this.appService.startUpdateCatalogTimer();
            } else {
                console.error('Invalid time format for hoursStart or hoursEnd');
            }

            this.updateFilterList(data.result.filter);
            this.prepareCatalog(data.result);
            this.updateStopList(data.result);
            callback(true);

        }, 'get_catalog_v4.php', false, loading);
    }

    showBranchPopup() {
        // Проверяем наличие branchPopup и mainPopup
        if (this.helpTexts.branchPopup && this.helpTexts.mainPopup) {
            // Если оба присутствуют, открываем модальное окно с mainPopup
            this.openFilialPopup(this.helpTexts.mainPopup, (data) => {
                // Если модальное окно закрыто и произошла акция 'close', открываем модальное окно с branchPopup
                if (data.action === 'close') {
                    this.openFilialPopup(this.helpTexts.branchPopup);
                }
            });
        } else {
            // Если только есть branchPopup, открываем его
            // Если только есть mainPopup, открываем его
            this.openFilialPopup(this.helpTexts.branchPopup || this.helpTexts.mainPopup);
        }
    }

    // Функция для открытия модального окна
    private openFilialPopup(popupData: any, callback?: (data: any) => void) {
        if (popupData) {
            this.appService.openModal(PopupMessageComponent, {
                isCloseButton: true,
                imgUrl: popupData.popupImg,
                title: popupData.popupTitle,
                subTitle: popupData.popupSubTitle,
                text: popupData.popupDescription,
                btnText: popupData.popupBtnText,
                btnLink: popupData.btnLink,
            }, callback);
        }
    }

    getCatalogStopList(callback: (status: boolean) => void, token: string = null, loading = false) {
        const getData = token ? {token} : {};
        this.getRequest(getData, (data: ApiResponse) => {
            this.updateFilterList(data.result.filter);
            this.updateStopList(data.result);
            callback(true);
        }, 'get_catalog_v4.php', false, loading);
    }

    updateStopList(data) {
        // функция обновления офферов товара
        const updateOffers = (source, target) => {
            if (source.offers?.length && target.offers?.length) {
                // проходим по офферам товара в каталоге
                for (const key in source.offers) {
                    if (typeof key !== 'undefined') {
                        // ищем в новом объекте товара такой же оффер
                        const offerIndex = target.offers.findIndex(i => i.id === source.offers[key].id);
                        if (offerIndex > -1) {
                            source.offers[key].isStopList = target.offers[offerIndex].isStopList;
                            // console.log(source.name + ' ' + key, target.offers[offerIndex].isStopList);
                        }
                    }
                }
            }
        };

        let product: Product;
        this.lastCheckedFavoritesIndex = -1;
        for (product of data.dishes) {
            if (product) {
                let index;

                // Обновляем главный массив товаров
                index = this.mainProductArray.findIndex(item => item.id === product.id);
                if (index > -1) {
                    this.mainProductArray[index].isStopList = product.isStopList;
                    updateOffers(this.mainProductArray[index], product);
                }

                if (this.products) {
                    // Обновляем нулевую категорию (Хит продаж)
                    index = this.products[0]?.findIndex(item => item.id === product.id);
                    if (index > -1) {
                        this.products[0][index].isStopList = product.isStopList;
                        updateOffers(this.products[0][index], product);
                    }
                    // Обновляем категорию текущего товара
                    index = this.products[product.categoryId]?.findIndex(item => item.id === product.id);
                    if (index > -1) {
                        this.products[product.categoryId][index].isStopList = product.isStopList;
                        updateOffers(this.products[product.categoryId][index], product);
                    }
                }

                // Обновление списка избранного
                index = this.favorites.findIndex(item => item.id === product.id);
                this.updateFavArray(product, index);
            }
        }

        // сортируем массивы по доступности товаров (isStopList)
        this.mainProductArray.sort(this.sortProductArray);
        for (const categoryId in this.products) {
            if (this.products.hasOwnProperty) {
                this.products[categoryId].sort(this.sortProductArray);
            }
        }

        this.eventUpdateStopList.next();
    }

    sortProductArray(a, b) {
        return b.isStopList ? -1 : 0;
    }

    // Функция для плавного отображения изменений списка на странице избранного
    updateFavArray(product: Product, favoritesIndex: number) {
        this.updateCatalogFavStates(product);

        // (T) Если данный продукт должен быть в избранном
        if (product?.isFavorite) {

            // (T.T) ... и уже существует в массиве favorites
            if (favoritesIndex > -1) {
                this.lastCheckedFavoritesIndex = favoritesIndex;
                product.animateTransition = null;

                // (T.F) Если данный продукт должен быть в избранном но еще не в массиве favorites...
            } else {
                // Вставляем текущий продукт на позицию сразу за последним проверенным
                // и в его свойствах передаем необходимость анимированно отобразить его в списке.
                product.animateTransition = 'show';
                this.favorites.splice(this.lastCheckedFavoritesIndex + 1, 0, product);
                this.lastCheckedFavoritesIndex++;
            }

            // (F) Если данный продукт не должен быть в избранном
        } else {

            // (F.T) ... но он существует в массиве favorites - скрываем его из favorites.
            if (favoritesIndex > -1) {
                this.favorites[favoritesIndex].animateTransition = 'hide';

                // Если он был первым в массиве, то lastCheckedIndex останется == -1
            }

            // (F.F) ... если продукт не должен быть в избранном и его там нет - идем дальше.
        }
    }

    updateCatalogFavStates(product: Product) {
        let i = this.products[product.categoryId]?.findIndex(item => item.id === product.id);
        try {
            this.products[product.categoryId][i].isFavorite = product?.isFavorite;
        } catch (e) {
            console.log('i', i);
            console.log('e', e);
        }

        i = this.mainProductArray.findIndex(item => item.id === product.id);
        this.mainProductArray[i].isFavorite = product?.isFavorite;
    }

    public findCategoryById(id) {
        return this.categories?.find((item) => {
            return item.id === id;
        });
    }

    public findByIdProperty(propertyName: string, propertyValue: any) {
        return this.mainProductArray?.filter((item) => {
            return item[propertyName]?.toString() === propertyValue?.toString();
        });
    }

    private prepareCatalog(data: Catalog) {
        const newDishes: Product[][] = [];
        let popular: Product[] = [];
        const catNames = [];
        const newFavoriteList: Product[] = [];

        this.phone = data.phone ? data.phone : '';
        this.appService.phone = this.phone;
        this.ymaps_apikey = data.ymaps_apikey;
        this.privacy_policy = data.privacy_policy;
        this.vacancies = data.vacancies;
        this.pizzaWOKStopList = data.stopList;
        this.settings = data.settings;
        this.statuses = data.statuses;

        this.helpTexts = data.helpTexts || {};

        for (const cat of data.categories) {
            catNames[cat.id] = cat.name;
        }

        for (const value of data.dishes) {
            if (typeof newDishes[value.categoryId] === 'undefined') {
                newDishes[value.categoryId] = [];
            }

            value.catName = catNames[value.categoryId];

            // if (value.isProductOfTheDay) {
            //     popular.push(value);
            // }

            // TODO: установить с новым API гарнир по умолчанию
            // if (value.categoryId === 69) {
            //     value.sideDish = [
            //         'с брокколи',
            //         'с булгуром',
            //         'с гречкой и грибами',
            //         'с диким рисом',
            //         'с картофельным пюре',
            //         'с мини картофелем и грибами',
            //         'с овощами гриль',
            //         'с перловкой и маслинами',
            //         'с ризони и брокколи',
            //         'с тушеной капустой тмином'
            //     ];
            //     value.activeSideDish = value.sideDish[0];
            // }

            if (value?.isFavorite) {
                newFavoriteList.push(value);
            }

            // Если value это ВОК конструктор или конструктор пицц-половинок и
            // в текущем филиале соответствующая категория в стоплисте - не добавлять в каталог
            if (!(
                (this.pizzaWOKStopList?.isWokConstructor && value?.isWok) ||
                (this.pizzaWOKStopList?.isPizzaConstructor && value?.isPizza)
            )) {
                newDishes[value.categoryId].push(value);
            }
        }

        const popularObject: any = {};

        for (const value of data.dishes) {
            if (value.isPopular) {
                popularObject[value.id] = value;
            }
        }

        popular = Object.values(popularObject);

        newDishes[0] = popular;
        // сортируем массивы по доступности товаров (isStopList)
        for (const key in newDishes) {
            if (newDishes.hasOwnProperty(key)) {
                newDishes[key] = newDishes[key].sort(this.sortProductArray);
            }
        }
        this.products = newDishes;

        this.favorites = newFavoriteList;

        data.categories.unshift({
            id: 0,
            name: 'Хит Продаж',
            picture: 'assets/img/main/dish6.jpg',
            defaultImg: null,
            type: CategoryTypes.Single,
        });

        this.appService.phone = data.phone;

        this.mainProductArray = data.dishes.sort(this.sortProductArray);
        this.categories = data.categories;


        this.promos = data.promotions;

        this.chunk = HelperService.chunk(this.categories, 2);
        this.flipperCount = Array(Math.max(this.chunk.length, popular.length));
        this.socials = data.socials;
        // после того как подгрузятся все необходимые данные - обновляются продукты.
        // это нужно для того, чтобы после истечения времени ланчей динамически
        // блокировалась возможность заказа ланчей без необходимости обновить страницу,
        // а также наоборот - разблокировалась, когда ланчи станут вновь доступны
        this.productsUpdated.next();
    }

    clearSelectedSideDish() {
        if (Object.keys(this.selectedSideDishes).length) {
            for (const prop in this.selectedSideDishes) {
                if (this.selectedSideDishes.hasOwnProperty(prop)) {
                    delete this.selectedSideDishes[prop];
                }
            }
        }
    }

    updateFilterList(filter) {
        const list = [];
        if (filter) {
            for (const i in filter) {
                if (i) {
                    list.push({key: i, title: filter[i]});
                }
            }
        }
        this.filterData = list;
    }

    changeFavoriteState(product: Product, callback: (status: any) => void) {
        const data = {
            id: product.id.toString()
        };

        this.postRequest(data, res => {
            if (res.status) {
                product.isFavorite = res.result;

                const favIndex = this.favorites.findIndex(item => item.id === product.id);
                // запускать мгновенный апдейт массива favorites только для удаления элементов
                // (для корректного и своевременного отображения на странице избранного)
                if (favIndex > -1) {
                    this.updateFavArray(product, favIndex);
                } else {
                    // иначе просто обновлять статусы isFavorite в массивах продуктов
                    this.updateCatalogFavStates(product);
                }
                this.updateFavorite$.emit();
            }
            callback(res);

        }, this.getUrl('catalog/saveFavorite', true), false, false);
    }

    deleteHiddenItems(callback: () => void) {
        setTimeout(() => {
            for (let i = 0; i < this.favorites.length; i++) {
                if (this.favorites[i]?.animateTransition === 'hide') {
                    this.favorites.splice(i, 1);
                    i--;
                }
            }
            callback();
        }, 300);
    }

    getHelpText(entity: string, code: string): string {
        if (this.helpTexts[entity]) {
            if (this.helpTexts[entity][code]) {
                return this.helpTexts[entity][code];
            }
        }

        return '';
    }
}
