import {Injectable} from '@angular/core';

function _window(): any {
    return window;
}

@Injectable()
export class LazyService {

    images = [];

    oldIos = false;

    constructor() {
        if (!('IntersectionObserver' in _window()) ||
            !('IntersectionObserverEntry' in _window()) ||
            !('intersectionRatio' in _window().IntersectionObserverEntry.prototype)) {
            this.oldIos = true;
        }
    }

    push(img) {
        this.images.push(img);
    }

    isExist(img) {
        return this.images.indexOf(img) !== -1;
    }
}
