import {Component, Input, OnInit} from '@angular/core';
import {CatalogService} from 'src/app/models/catalog.service';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-popup-order-finish-iiko-error',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
})
export class PopupOrderFinishIikoError implements OnInit {
     appService: AppService;
    catalogService: CatalogService;

    @Input() text = '';
    @Input() button = 'Понятно';
    @Input() orderId = null;

    phone: string;

    constructor(
        appService: AppService,
        catalogService: CatalogService
    ) {
        this.appService = appService;
        this.catalogService = catalogService;
    }

    ngOnInit() {
        this.phone = this.catalogService.phone;
    }

    close() {
        this.appService.closeModal();
    }
}
