import {Entity} from '../core/main.entity';

export class Address implements Entity {
    id = 0;
    name: string;
    street: string;
    porch: string;
    floor: string;
    office: string;
    intercom: string;
    info?: string;
    comment: string;
    house: any;
    building?: any;
    block: string;
    uri: string;
    x: string;
    y: string;
    kladrId: string;
    value: string;
}
