import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {Branch} from '../entity/restaurant.entity';
import {ApiResponse} from '../entity/apiResponse.entity';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class BranchService extends MainService {
    get data(): Branch[] {
        return this.privateData;
    }

    set data(value: Branch[]) {
        this.privateData = value;
        this.isDataSubject.next(!!value);
    }

    private privateData: Branch[] = [];
    isDataSubject = new BehaviorSubject<boolean>(false);

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    public getData(callback: (status: boolean) => void, loading = false, isRefresh = false) {
        if (this.data.length === 0 || isRefresh) {
            /*
            * @todo вынести сити айди выше
            * */
            const city = localStorage.getItem('com.ionicframework.hatimaki__city');
            const options = {
                city
            };
            this.postRequest(options, (data: ApiResponse) => {
                this.data = data.result;

                callback(true);
            }, 'restaurants.php', true, loading);
        } else {
            callback(true);
        }
    }

    public findBranchById(branchId) {
        return this.data.find(b => b.id === branchId);
    }

    public findBranchByGuid(guid): Branch {
        return this.data.find(b => b.guid === guid);
    }
}
