import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CartService} from '../../models/cart.service';
import {AppService} from '../../services/app.service';
import {Subject} from 'rxjs';
import {MainModal} from '../../core/main.Modal';
import {ModalService} from '../../services/modal.service';
import {GestureController} from '@ionic/angular';
import Scrollbar from 'smooth-scrollbar';
import CustomScrollBarPlugin from '../../core/CustomScrollBarPlugin';
import {SwiperConfigInterface, SwiperPaginationInterface} from 'ngx-swiper-wrapper';
import {Branch} from '../../entity/restaurant.entity';


@Component({
    selector: 'branch-modal',
    templateUrl: 'item.html',
    styleUrls: ['item.scss'],
})

export class BranchModalComponent extends MainModal implements OnInit {
    BACK_DROP_OPACITY = 0.3;
    @ViewChild('scrollContainer', {static: false})
    scrollContainer;

    @Input() branch: Branch;
    @Output() setBranch: EventEmitter<any> = new EventEmitter<any>();
    readyToClose = false;


    private pagination: SwiperPaginationInterface = {
        el: '.branch-pagination',
        clickable: true,
        hideOnClick: false,
        dynamicBullets: true,
        dynamicMainBullets: 7
    };

    branchOptions: SwiperConfigInterface = {
        pagination: this.pagination,
        slidesPerView: 1,
        loop: true
    };

    constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController
    ) {
        super(appService, modalService, gestureCtrl);
    }

    ngOnInit() {
        setTimeout(() => {
            this.readyToClose = true;
        }, 150);
    }

    selectBranch() {
        this.setBranch.emit({
            action: 'setBranch',
            branch: this.branch,
        });
        this.modalService.close();
    }

    closeModal() {
        if (this.readyToClose) {
            this.close();
        }
    }

    goToYandexMap(branch: Branch) {
        const zoomLevel = 16; // Уровень зума
        const yandexUrl = `https://yandex.ru/maps/?pt=${branch.coord_lon},${branch.coord_lat}&z=${zoomLevel}&l=map`;
        this.appService.openLink(yandexUrl);
    }
}
