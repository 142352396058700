import { Gesture, GestureController } from '@ionic/angular';
import { AppService } from '../services/app.service';
import { ModalService } from '../services/modal.service';
import { Directive, ElementRef, ViewChild } from '@angular/core';

@Directive()
export abstract class MainModal {
    protected BACK_DROP_OPACITY = 1;
    protected swipeToTop = false;

    @ViewChild('modalOverlay', { read: ElementRef, static: false })
    set promoModalOverlayRef(el: ElementRef) {
        if (el) {
            this.modalOverlayElement = el.nativeElement.querySelector('.inner-wrapper');
        }
    }

    @ViewChild('modalComponent', { read: ElementRef, static: false })
    set promoModalRef(el: ElementRef) {
        if (el) {
            this.modalElement = el.nativeElement;
            this.animateModal();
        }
    }

    progress = 0;

    appService: AppService;
    modalService: ModalService;
    protected gestureCtrl: GestureController;

    protected modalElement: any;
    protected modalOverlayElement: any;
    private gesture: Gesture;
    private readonly MAX_TRANSLATE: number = 0;
    private startDeltaY = 0;
    protected lockGesture = false;

    protected constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController
    ) {
        this.appService = appService;
        this.modalService = modalService;
        this.gestureCtrl = gestureCtrl;
        this.MAX_TRANSLATE = this.appService.getScreenHeight();
    }

    animateModal() {
        this.setTransition(true);
        this.setAnimation(this.progress);
        this.createSwiperGesture();
    }

    setAnimation(value: number) {
        this.modalElement.style.transform = 'translateY(' + value + 'px)';
        this.modalOverlayElement.style.opacity = (this.BACK_DROP_OPACITY * (100 - Math.round(value / this.MAX_TRANSLATE * 100))) + '%';
    }

    createSwiperGesture() {
        this.gesture = this.gestureCtrl.create({
            el: this.modalElement,
            threshold: 10,
            gestureName: 'swipe',
            direction: 'y',
            passive: false,
            canStart: () => this.canStart(),
            onWillStart: async () => this.onWillStart(),
            onMove: evt => this.onMove(evt),
            onEnd: evt => this.onEnd(evt)
        });

        this.gesture.enable(true);
    }

    onWillStart() {
        this.startDeltaY = 0;
        this.setTransition(false);
    }

    protected canStart(): boolean {
        return true;
    }

    protected onMove(evt) {
        if (!this.modalService.isScrollTop || this.lockGesture) {
            return;
        } else if (this.startDeltaY === 0) {
            this.modalService.isGesture = true;
            this.startDeltaY = evt.deltaY;
        }

        let value = this.progress + evt.deltaY - this.startDeltaY;
        value = this.swipeToTop ? value > 0 ? 0 : value : value < 0 ? 0 : value;
        value = value > this.MAX_TRANSLATE ? this.MAX_TRANSLATE : value;
        this.setAnimation(value);
    }

    protected onEnd(evt) {
        if (!this.modalService.isGesture) {
            return;
        }
        this.modalService.isGesture = false;
        this.setTransition(true);
        if (this.swipeToTop) {
            if (this.progress + evt.deltaY - this.startDeltaY < -100) {
                this.close();
            } else {
                this.progress = 0;
            }
        } else {
            if (this.progress + evt.deltaY - this.startDeltaY > 300) {
                this.close();
            } else {
                this.progress = 0;
            }
        }

        this.setAnimation(this.progress);
    }

    /**
     * Вызывает закрытие модального окна
     */
    close() {
        // добавляем transition анимациям
        this.setTransition(true);
        // изменяем направление закрытия в зависимости от переменной swipeToTop
        this.progress = this.swipeToTop ? -this.MAX_TRANSLATE : this.MAX_TRANSLATE;
        this.setAnimation(this.progress);
        // после анимации через 250мс закрываем модалку
        const closeTimer = setTimeout(() => {
            this.onClose();
            clearTimeout(closeTimer);
        }, 250);
    }

    /**
     * Вызывается при закрытии модального окна
     * @protected
     */
    protected onClose() {
        this.modalService.close();
    }

    /**
     * Включает или отключает transition у контейнера модального окна в зависимости от value
     * @param value
     * @private
     */
    private setTransition(value: boolean) {
        if (value) {
            this.modalElement.style.transition = 'transform 0.25s ease-out';
            this.modalOverlayElement.style.transition = 'opacity 0.25s ease-out';
        } else {
            this.modalElement.style.transition = 'transform 0.1s linear';
            this.modalOverlayElement.style.transition = 'opacity 0.05s linear';
        }
    }
}
