import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {AppService} from '../../../services/app.service';
import {UserService} from '../../../models/user.service';

@Component({
    selector: 'app-popup-delete-profile',
    templateUrl: './popup-delete-profile.component.html',
    styleUrls: ['./popup-delete-profile.component.scss'],
})
export class PopupDeleteProfileComponent implements OnInit {

    /*
    * @todo вынести общие для всех модлаьных окон свойства и методы в парент и отнаследовать, стили тоже
    * */

    private modalController: ModalController;
    sanitization: DomSanitizer;
    @Input() title?: string;
    @Input() text?: string;
    @Input() btnText?: string;
    @Input() btnCloseText?: string;

    private appService: AppService;
    private userService: UserService;

    constructor(
        modalController: ModalController,
        sanitization: DomSanitizer,
        appService: AppService,
        userService: UserService
    ) {
        this.modalController = modalController;
        this.sanitization = sanitization;
        this.appService = appService;
        this.userService = userService;
    }

    ngOnInit() {
    }

    close() {
        this.appService.closeModal();
    }

    deleteProfile() {
        this.userService.postDeleteProfile();
    }
}
