import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppService } from '../../services/app.service';
import { MainModal } from '../../core/main.Modal';
import { ModalService } from '../../services/modal.service';
import { GestureController } from '@ionic/angular';

@Component({
    selector: 'location-changed-modal',
    templateUrl: 'item.html',
    styleUrls: ['item.scss'],
})
export class LocationChangedModalComponent extends MainModal implements OnInit {
    BACK_DROP_OPACITY = 0.3;
    @ViewChild('scrollContainer', { static: false })
    scrollContainer;

    @Input() address: string;
    @Output() setEvent: EventEmitter<any> = new EventEmitter<any>();
    readyToClose = false;

    constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController
    ) {
        super(appService, modalService, gestureCtrl);
    }

    ngOnInit() {
        this.swipeToTop = true;
        setTimeout(() => {
            this.readyToClose = true;
        }, 150);
    }

    closeModal() {
        if (this.readyToClose) {
            this.close();
        }
    }

    goToLocation() {
        this.setEvent.emit({
            action: 'goToLocationPage'
        });
        this.modalService.close();
    }
}
