import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {HistoryService} from 'src/app/models/history.service';
import {SurveyService} from 'src/app/models/survey.service';
import {UserService} from 'src/app/models/user.service';
import {MainItem} from '../../core/main.item';
import {CartService} from '../../models/cart.service';
import {AppService} from '../../services/app.service';
import {NavigationService} from '../../services/navigation.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'bottom-menu-item',
    templateUrl: 'item.html'
})

export class BottomMenuItemComponent extends MainItem implements OnDestroy, OnInit {

    cartService: CartService;
    historyService: HistoryService;
    appService: AppService;
    navigationService: NavigationService;
    userService: UserService;
    surveyService: SurveyService;
    cdr: ChangeDetectorRef;

    private subscription: Subscription = null;

    constructor(
        cartService: CartService,
        appService: AppService,
        navigationService: NavigationService,
        historyService: HistoryService,
        userService: UserService,
        surveyService: SurveyService,
        cdr: ChangeDetectorRef
    ) {
        super();

        this.appService = appService;
        this.cartService = cartService;
        this.historyService = historyService;
        this.navigationService = navigationService;
        this.userService = userService;
        this.surveyService = surveyService;
        this.cdr = cdr;
    }

    ngOnInit() {
        this.subscription = this.cartService.updateQuantity$.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    goToHome() {
        this.appService.resetMenuMainSwiper();
        this.navigationService.goToPage('menu', true);
    }

    goToCart() {
        this.navigationService.goToPage('cart');
    }

    goToProfile() {
        this.navigationService.goToPage('profile/main');
    }

    goToFavorite() {
        this.navigationService.goToPage('favorite');
    }
}
