import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {AppService} from '../../../services/app.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-popup-deposit',
    templateUrl: './popup-message.component.html',
    styleUrls: ['./popup-message.component.scss'],
})
export class PopupMessageComponent implements OnInit {

    /*
    * @todo вынести общие для всех модлаьных окон свойства и методы в парент и отнаследовать, стили тоже
    * */

    private modalController: ModalController;
    sanitization: DomSanitizer;
    @Input() title?: string;
    @Input() subTitle?: string;
    @Input() text?: string;
    @Input() imgUrl?: string;
    @Input() btnText?: string;
    @Input() btnLink?: string;
    @Input() isCloseButton = false;
    @Input() isSbpAwaitPayModal = false;
    private appService: AppService;
    private closeSubscription: Subscription = null;

    constructor(
        modalController: ModalController,
        sanitization: DomSanitizer,
        appService: AppService,
    ) {
        this.modalController = modalController;
        this.sanitization = sanitization;
        this.appService = appService;
    }

    ngOnInit() {
        // Подписываемся на закрытие через appService
        this.closeSubscription = this.appService.eventClosePopupMessageModal.subscribe(() => {
            this.close();
        });
    }

    closeToOverlay(){
        if (!this.isSbpAwaitPayModal) {
            this.close();
        }
    }

    close() {
        this.appService.closeModal();
    }

    openLink() {
        window.open(this.btnLink);
    }

    getImageUrl() {
        return this.sanitization.bypassSecurityTrustUrl(this.imgUrl);
    }
}
