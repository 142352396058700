import {Entity} from '../core/main.entity';

export class AddressSearchAnswer implements Entity {
    terminalOverloaded: boolean;
    deliveryTime: string;
    deliveryTimeText?: string;
    deliveryTimeTextBtn?: string;
    pickUpTime: number;
    'zone_id'?: string;
    'terminal_id': string;
    'terminal_name': string;
    'terminal_bid': string;
    x: number;
    y: number;
    'min_price': number;
    'errors'?: [
        string
    ];
    addressInfo?: any;
    addressUnavailable?: {
        underAddressText: string;
        title: string;
        text: string;
        type?: number;
    };
    timeOptions: {
        start: string,
        end: string,
        delay: string
    };
}
