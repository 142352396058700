import {Animation} from '@ionic/core';
import {AnimationController} from '@ionic/angular';

export function popupEnterAnimation(baseEl: HTMLElement): Animation {

    const animationController = new AnimationController();
    const baseAnimation = animationController.create();

    const backdropAnimation = animationController.create();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    const wrapperAnimation = animationController.create();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .beforeStyles({
            transform: 'translate(0)',
            opacity: 0
        })
        .easing('ease-in-out')
        .duration(200)
        .fromTo('opacity', 0.01, 1);

    backdropAnimation
        .easing('ease-in-out')
        .duration(200)
        .fromTo('opacity', 0.01, 1)
        .fromTo('background', 'rgba(0,0,0,0)', 'rgba(0,0,0,0.5)')
        .fromTo('webkitBackdropFilter', 'blur(0px)', 'blur(5px)')
        .fromTo('backdropFilter', 'blur(0px)', 'blur(5px)');

    return animationController.create().addAnimation([baseAnimation, backdropAnimation, wrapperAnimation]);


}

export function popupLeaveAnimation(baseEl: HTMLElement): Animation {

    const animationController = new AnimationController();
    const baseAnimation = animationController.create();

    const backdropAnimation = animationController.create();
    backdropAnimation.addElement(baseEl.querySelector('ion-backdrop'));

    const wrapperAnimation = animationController.create();
    wrapperAnimation.addElement(baseEl.querySelector('.modal-wrapper'));

    wrapperAnimation
        .easing('ease-in-out')
        .duration(200)
        .fromTo('opacity', 1, 0.01)
        .afterStyles({
            transform: 'translate(0)',
            opacity: 0
        });

    backdropAnimation
        .easing('ease-in-out')
        .duration(200)
        .fromTo('opacity', 1, 0.01)
        .fromTo('background', 'rgba(0,0,0,0.5)', 'rgba(0,0,0,0)')
        .fromTo('webkitBackdropFilter', 'blur(5px)', 'blur(0px)')
        .fromTo('backdropFilter', 'blur(5px)', 'blur(0px)');

    return animationController.create().addAnimation([baseAnimation, backdropAnimation, wrapperAnimation]);


}
