import {ScrollbarPlugin} from 'smooth-scrollbar';

type Data2d = {
    x: number,
    y: number
};

export default class CustomScrollBarPlugin extends ScrollbarPlugin {
    static pluginName = 'myPlugin';
    private oldScroll = 0;

    onInit() {
        super.onInit();
    }

    transformDelta(delta: Data2d, event: Event): Data2d {
        const isGesture = this.options.isModalGesture();
        return {
            x: 0,
            y: isGesture ? 0 : delta.y
        };
    }

    onRender(remainMomentum: Data2d) {
        if (this.oldScroll !== this.scrollbar.offset.y) {
            this.oldScroll = this.scrollbar.offset.y;

            const selector = this.options.parallaxSelector || '.parallax-container';
            const imgContainer = this.scrollbar.contentEl.querySelector(selector) as HTMLElement;
            if (imgContainer) {
                let scroll = this.scrollbar.offset.y;
                scroll = scroll > 230 ? 230 : scroll;
                scroll = scroll < 0 ? 0 : scroll;
                const imgMargin = scroll / 4;

                imgContainer.style.backgroundPosition = '50% calc(50% + ' + imgMargin + 'px)';
            }
        }

    }
}
