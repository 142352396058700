import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {ApiResponse} from '../entity/apiResponse.entity';
import {PizzaItem, PizzaSizeType} from '../entity/pizzaItem.entity';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class PizzaService extends MainService {

    apiUrl = environment.newApiUrl;
    public data: PizzaItem[] = [];
    options: any;

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);

        // let a = {
        //     cartId: '8937_PIZZA_8969_9043',
        //     title: 'Пицца из двух половинок app',
        // }

        // this.options = {
        //     mainId: 8937, // главный айди
        //     title: 'Пицца из двух половинок app', // тайтл товара, можно захордкодить
        //     img: 'https://new.hatimaki.ru/upload/iblock/f60/f609798e47383a5fc194644e7f3b51cb.png', // фото товара
        //     size: [ // можно захордкодить
        //         '33 см',
        //         '40 см',
        //     ]
        // };

        // this.data = [
        //     {
        //         id: 1,
        //         title: 'Морской коктейль томатная',
        //         desc: 'креветки, лосось, кальмар, мидии, сыр Моцарелла, соус крем-чиз, цукини, базилик',
        //         img: 'assets/img/pizza/pizza-example.png',
        //         sizes: [
        //             {
        //                 id: 8969,
        //                 sizeType: 1,
        //                 price: 350,
        //                 fprice: 350,
        //                 oldPrice: 350,
        //                 weight: '210 г.',
        //                 nutritionalValue: {
        //                     proteins: '10',
        //                     fats: '10',
        //                     carbo: '10',
        //                     kkal: '10',
        //                 }
        //             },
        //             {
        //                 id: 9043,
        //                 sizeType: 2,
        //                 price: 450,
        //                 fprice: 450,
        //                 oldPrice: 450,
        //                 weight: '310 г.',
        //                 nutritionalValue: {
        //                     proteins: '20',
        //                     fats: '20',
        //                     carbo: '20',
        //                     kkal: '20',
        //                 }
        //             }
        //         ]
        //     },
        //     {
        //         id: 2,
        //         title: 'Барбекю BBQ',
        //         desc: 'кабачок, шампиньоны, томаты Черри, болгарский перец, маслины, фасоль стручковая, соус BBQ, фирменный томатный соус',
        //         img: 'assets/img/pizza/pizza-example.png',
        //         sizes: [
        //             {
        //                 id: 9635,
        //                 sizeType: 1,
        //                 price: 430,
        //                 fprice: 430,
        //                 oldPrice: 430,
        //                 weight: '210 г.',
        //                 nutritionalValue: {
        //                     proteins: '10',
        //                     fats: '10',
        //                     carbo: '10',
        //                     kkal: '10',
        //                 }
        //             },
        //             {
        //                 id: 9637,
        //                 sizeType: 2,
        //                 price: 550,
        //                 fprice: 550,
        //                 oldPrice: 550,
        //                 weight: '310 г.',
        //                 nutritionalValue: {
        //                     proteins: '20',
        //                     fats: '20',
        //                     carbo: '20',
        //                     kkal: '20',
        //                 }
        //             }
        //         ]
        //     },
        //     {
        //         id: 3,
        //         title: 'Морской коктейль томатная',
        //         desc: 'креветки, лосось, кальмар, мидии, сыр Моцарелла, соус крем-чиз, цукини, базилик',
        //         img: 'assets/img/pizza/pizza-example.png',
        //         sizes: [
        //             {
        //                 id: 8716,
        //                 sizeType: 1,
        //                 price: 200,
        //                 fprice: 200,
        //                 oldPrice: 200,
        //                 weight: '210 г.',
        //                 nutritionalValue: {
        //                     proteins: '10',
        //                     fats: '10',
        //                     carbo: '10',
        //                     kkal: '10',
        //                 }
        //             },
        //             {
        //                 id: 8717,
        //                 sizeType: 2,
        //                 price: 300,
        //                 fprice: 300,
        //                 oldPrice: 300,
        //                 weight: '310 г.',
        //                 nutritionalValue: {
        //                     proteins: '20',
        //                     fats: '20',
        //                     carbo: '20',
        //                     kkal: '20',
        //                 }
        //             }
        //         ]
        //     },
        //     {
        //         id: 4,
        //         title: 'Барбекю BBQ',
        //         desc: 'кабачок, шампиньоны, томаты Черри, болгарский перец, маслины, фасоль стручковая, соус BBQ, фирменный томатный соус',
        //         img: 'assets/img/pizza/pizza-example.png',
        //         sizes: [
        //             {
        //                 id: 8722,
        //                 sizeType: 1,
        //                 price: 520,
        //                 fprice: 520,
        //                 oldPrice: 520,
        //                 weight: '210 г.',
        //                 nutritionalValue: {
        //                     proteins: '10',
        //                     fats: '10',
        //                     carbo: '10',
        //                     kkal: '10',
        //                 }
        //             },
        //             {
        //                 id: 8723,
        //                 sizeType: 2,
        //                 price: 620,
        //                 fprice: 620,
        //                 oldPrice: 620,
        //                 weight: '310 г.',
        //                 nutritionalValue: {
        //                     proteins: '20',
        //                     fats: '20',
        //                     carbo: '20',
        //                     kkal: '20',
        //                 }
        //             }
        //         ]
        //     },
        //     {
        //         id: 5,
        //         title: 'Морской коктейль томатная',
        //         desc: 'креветки, лосось, кальмар, мидии, сыр Моцарелла, соус крем-чиз, цукини, базилик',
        //         img: 'assets/img/pizza/pizza-example.png',
        //         sizes: [
        //             {
        //                 id: 8716,
        //                 sizeType: 1,
        //                 price: 280,
        //                 fprice: 280,
        //                 oldPrice: 280,
        //                 weight: '210 г.',
        //                 nutritionalValue: {
        //                     proteins: '10',
        //                     fats: '10',
        //                     carbo: '10',
        //                     kkal: '10',
        //                 }
        //             },
        //             {
        //                 id: 8717,
        //                 sizeType: 2,
        //                 price: 320,
        //                 fprice: 320,
        //                 oldPrice: 320,
        //                 weight: '310 г.',
        //                 nutritionalValue: {
        //                     proteins: '20',
        //                     fats: '20',
        //                     carbo: '20',
        //                     kkal: '20',
        //                 }
        //             }
        //         ]
        //     },
        // ];

        // var a = {
        //     'status': true,
        //     'message': '',
        //     'result': {
        //         'optons': {
        //             'mainId': 8937, // главный айди
        //             'title': 'Пицца из двух половинок app', // тайтл товара, можно захордкодить
        //             'img': 'https://new.hatimaki.ru/upload/iblock/f60/f609798e47383a5fc194644e7f3b51cb.png', // фото товара
        //             'size': [ // можно захордкодить
        //                 '33 см',
        //                 '40 см',
        //             ]
        //         },
        //         'data': [ // тут наши пиццы
        //             {
        //                 'id': '8968',
        //                 'title': '4 \u0441\u0435\u0437\u043e\u043d\u0430',
        //                 'desc': '', // забыли деск
        //                 'img': 'https:\/\/new.hatimaki.ru', // а можно фотку?
        //                 'sizes': [
        //                     {
        //                         'id': 8969,
        //                         'sizeType': 1,
        //                         'price': 250,
        //                         'fprice': '250.00',  // тайп флоат
        //                         'oldPrice': '250.00',  // тайп флоат
        //                         'weight': '1000' // добавить 'г.'
        //                     },
        //                     {
        //                         'id': 9043,
        //                         'sizeType': 2,
        //                         'price': 344,
        //                         'fprice': '344.00',
        //                         'oldPrice': '344.00',
        //                         'weight': '1000'
        //                     }
        //                 ]
        //             }
        //         ]
        //     }
        // };
    }


    // public getPizzas(callback: (status: boolean) => void) {
    //     if (this.data.length === 0) {
    //         this.getRequest({}, (wok: ApiResponse) => {
    //             this.data = wok.result.data;
    //             this.options = wok.result.options;
    //             callback(true);
    //         }, 'pizzas');
    //     } else {
    //         callback(true);
    //     }
    // }

    public selectPizzaParts(idLeft: number, idRight: number) {
        return [
            this.findById(idLeft),
            this.findById(idRight),
        ];
    }

    public findSizePart(pizza: PizzaItem, size: PizzaSizeType) {
        return pizza.sizes.find((item) => {
            return item.sizeType === size;
        });
    }

    public getCartIdCommonPart() {
        return this.options.mainId + '_PIZZA_';
    }

    public getCartId(selectedPizzas: PizzaItem[], size: PizzaSizeType) {
        return this.getCartIdCommonPart()
            + [this.findSizePart(selectedPizzas[0], size).id, this.findSizePart(selectedPizzas[1], size).id].join('_');
    }

    public getPizzaTitle(selectedPizzas: PizzaItem[], size: PizzaSizeType) {
        const sizeText = this.options.size[size - 1].replace(' ', '');

        return `${sizeText} ${selectedPizzas[0].title} + ${selectedPizzas[1].title}`;
    }

    public getCartObject(selectedPizzas: PizzaItem[], size: PizzaSizeType) {
        return {
            id: this.getCartId(selectedPizzas, size),
            name: this.options.title,
            catName: this.getPizzaTitle(selectedPizzas, size),
            price: this.getTotalPrice(selectedPizzas, size),
            oldPrice: this.getTotalOldPrice(selectedPizzas, size),
            fprice: this.getTotalFPrice(selectedPizzas, size),
            offer: null,
            options: [],
            imageUrl: this.options.img,
        };
    }

    public getTotalPrice(selectedPizzas: PizzaItem[], size: PizzaSizeType) {
        return this.findSizePart(selectedPizzas[0], size).price +
            this.findSizePart(selectedPizzas[1], size).price;
    }

    public getTotalOldPrice(selectedPizzas: PizzaItem[], size: PizzaSizeType) {
        return this.findSizePart(selectedPizzas[0], size).oldPrice +
            this.findSizePart(selectedPizzas[1], size).oldPrice;
    }

    public getTotalFPrice(selectedPizzas: PizzaItem[], size: PizzaSizeType) {
        return this.findSizePart(selectedPizzas[0], size).fprice +
            this.findSizePart(selectedPizzas[1], size).fprice;
    }

    public findPizzaBySize(id: string): any {
        let res: any = null;

        this.data.forEach((item, index) => {
            item.sizes.forEach((sizeItem) => {

                if (res === null) {
                    res = sizeItem.id.toString() === id ? {
                        pizza: item,
                        index,
                        size: sizeItem.sizeType
                    } : null;
                } else {
                    return;
                }

            });
        });

        return res;
    }

    public getSelectedPizzasByCartId(cartId: string) {
        const pizzasIds = cartId.replace(this.getCartIdCommonPart(), '').split('_');

        // console.log(pizzasIds);

        const pizzaLeft = this.findPizzaBySize(pizzasIds[0]);
        const pizzaRight = this.findPizzaBySize(pizzasIds[1]);

        // console.log(pizzaLeft);
        // console.log(pizzaRight);

        const selectedPizzas = [
            pizzaLeft?.pizza,
            pizzaRight?.pizza,
        ];

        const size = pizzaLeft?.size;

        return {
            selectedPizzas,
            size,
            index: [pizzaLeft?.index + 1, pizzaRight?.index + 1]
        };
    }

    clear() {
        this.data = [];
    }
}
